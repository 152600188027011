import { SESSION_STORAGE_KEY, SearchAction } from '../enums';
import type { InitializedSearchProps } from '../interfaces';

/**
 *  Saves search-related data in the session storage of the browser.
 *  The data gets picked up by `ah-analytics-utils` on the page with search results.
 * @param query
 * @param suggestions
 * @param selectedSuggestion
 * @param searchAction
 */
export function initializeSearchEvent({
    query,
    suggestions,
    selectedSuggestion,
    searchAction,
}: InitializedSearchProps) {
    // sessionStorage does not exist on the server
    if (typeof window === 'undefined') {
        return;
    }

    if (!query) {
        return;
    }

    const initializedSearch = JSON.stringify({
        query,
        suggestions,
        selectedSuggestion,
        timestamp: Date.now(),
        searchAction: searchAction || SearchAction.SEARCH,
    });

    window.sessionStorage.setItem(SESSION_STORAGE_KEY, initializedSearch);
}
