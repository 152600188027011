import pkg from '../../../package.json';
import { UserLoginStatus } from '../../events/_default/enums';
import type {
    EventRaw,
    StandardEvent,
    StandardEventMetaData,
} from '../../events/_default/interfaces';
import { PageSource } from '../../events/page/enums';
import { debugCidSessionStorageKey } from './constants';
import type { PageType } from './enums/page-types';
import { getConsent, getConsentBeta } from './helpers/get-consent';
import { getDeviceType } from './helpers/get-device-type';
import { getPageType } from './helpers/get-page-type';
import { logEvent } from './helpers/log-events';
import { normalizeData } from './helpers/normalize-data';
import type {
    DataLayerCallback,
    DataLayerProps,
    PageMeta,
    StandardData,
    TempData,
} from './interfaces';

const HISTORY_MAX_LENGTH = 50;

function executeCallback(event: StandardEvent, fn: DataLayerCallback) {
    try {
        fn(event);
    } catch (error) {
        console.warn('Error executing analytics callback', error);
    }
}

export class DataLayer {
    // properties and methods are made private with #
    // prefix, to make sure that data layer info is
    // not easily accessible to external agents
    #callbacks: DataLayerCallback[] = [];

    #history: StandardEvent[] = [];

    #data: StandardData = {
        user: {
            userLoginStatus: UserLoginStatus.LOGGEDOUT,
        },
    };

    // temporary obj needed to store data that was on
    // ahDataLayer (deprecated) for marketing tracking
    #temp: TempData = {};

    #pageType = 'unknown';

    #pageSource = PageSource.WEB;

    #historyMaxLength = HISTORY_MAX_LENGTH;

    version = pkg.version;

    constructor(dataProps: DataLayerProps = {}) {
        const { debug, pageType, pageSource, historyMaxLength, temp, ...data } =
            normalizeData(dataProps);

        this.#data = data;

        if (pageType) {
            this.#pageType = pageType;
        }

        if (pageSource) {
            this.#pageSource = pageSource;
        }

        if (debug) {
            this.debug();
        }

        if (historyMaxLength) {
            this.#historyMaxLength = historyMaxLength;
        }

        if (temp) {
            this.#temp = temp;
        }
    }

    /** Information about standard data can be found at https://confluence-aholddelhaize.atlassian.net/wiki/spaces/ENBL/pages/148364789819/Standard+Header+Properties+of+an+event#3.2-Parameters */
    get data(): StandardEventMetaData {
        const page = this.getPage();
        const consent = this.getConsent();
        const consentBeta = this.getConsentBeta();
        const timestamp = Date.now();

        return {
            page,
            consent,
            consentBeta,
            timestamp,
            dataSource: 'web',
            deviceType: getDeviceType(),
            ...this.#data,
        };
    }

    get temp(): TempData {
        return this.#temp;
    }

    get history(): StandardEvent[] {
        return this.#history;
    }

    get pageType(): PageType | string {
        const pagePath = window.location.pathname;
        return getPageType(pagePath, this.#pageType);
    }

    get debugMode(): boolean {
        return !!sessionStorage.getItem(debugCidSessionStorageKey);
    }

    /** Make the function available on the data layer */
    getConsent(): string {
        return getConsent();
    }

    /** new cookie consent - Make the function available on the data layer */
    getConsentBeta(): string {
        return getConsentBeta();
    }

    getPage(): PageMeta {
        const pagePath = window.location.pathname;

        return {
            pageType: this.pageType,
            pageSource: this.#pageSource,
            pageName: document.title,
            pageUrl: window.location.href,
            pageHostname: window.location.host,
            pagePath,
        };
    }

    updateData(dataProps: Partial<DataLayerProps>): void {
        const data = normalizeData(dataProps);

        this.#data = {
            ...this.#data,
            ...data,
        };
    }

    updateTempData(tempData: Partial<TempData>): void {
        this.#temp = {
            ...this.#temp,
            ...tempData,
        };
    }

    subscribe(fn: DataLayerCallback): () => void {
        if (typeof fn === 'function') {
            this.#callbacks.push(fn);
            this.#history.forEach(event => {
                executeCallback(event, fn);
            });

            // Return unsubscribe
            return () => {
                this.#callbacks = this.#callbacks.filter(f => f !== fn);
            };
        }
        console && console.warn('Subscribe requires a function parameter');
        return () => undefined;
    }

    debug(): void {
        console.log('DEBUG-CID: Debug mode on for CID datalayer');
        sessionStorage.setItem(debugCidSessionStorageKey, 'true');

        // log all events currently in history
        this.#history.forEach(event => {
            logEvent(event);
        });
    }

    debugOff(): void {
        console.log('DEBUG-CID: Debug mode off for CID datalayer');
        sessionStorage.removeItem(debugCidSessionStorageKey);
    }

    #enrichEvent(event: EventRaw): StandardEvent {
        return {
            ...this.data,
            ...event,
        };
    }

    #addToHistory(event: StandardEvent): void {
        // remove first item if history has reached max length
        if (this.#history?.length >= this.#historyMaxLength) {
            this.#history.shift();
        }

        this.#history.push(event);

        if (this.debugMode) {
            logEvent(event);
        }
    }

    emit(event: EventRaw): void {
        const enrichedEvent = this.#enrichEvent(event);
        this.#addToHistory(enrichedEvent);

        this.#callbacks.forEach(fn => executeCallback(enrichedEvent, fn));
    }
}
