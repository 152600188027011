import { gtmDataLayerName } from '../constants';

export const setInitializedForGtm = () => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const windowObj = window as any;

    windowObj[gtmDataLayerName] = windowObj[gtmDataLayerName] || [];

    windowObj[gtmDataLayerName].push({
        event: 'cidDataLayerInitialized',
        timestamp: Date.now(),
    });
};
