import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { ComponentAction } from '../enums';
import type { ComponentEventDetails, ComponentMeta } from '../interfaces';

export const emitComponentEvent = ({
    component,
    componentAction,
}: ComponentEventDetails): void => {
    emitEvent({
        eventName: EventName.COMPONENT_INTERACTION,
        componentAction,
        component,
    });
};

export const emitClickComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.CLICK,
    });
};

export const emitViewComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.VIEW,
    });
};

export const emitSwipeComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.SWIPE,
    });
};

export const emitSubmitComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.SUBMIT,
    });
};

export const emitAddComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.ADD,
    });
};

export const emitRemoveComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.REMOVE,
    });
};

export const emitErrorComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.ERROR,
    });
};

export const emitValidComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.VALID,
    });
};

export const emitCloseComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.CLOSE,
    });
};

export const emitCancelComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.CANCEL,
    });
};

export const emitUndoComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.UNDO,
    });
};

export const emitAcceptComponent = (component: ComponentMeta): void => {
    emitComponentEvent({
        component,
        componentAction: ComponentAction.ACCEPT,
    });
};
