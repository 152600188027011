import type { VideoMeta, VideoMetaRaw } from '../interfaces';

export const normalizeVideoMeta = (videoRaw: VideoMetaRaw): VideoMeta => {
    const {
        name,
        url,
        provider,
        id,
        videoDuration,
        percentageWatched = '',
    } = videoRaw;

    return {
        videoName: name,
        videoUrl: url,
        videoProvider: provider,
        videoId: id,
        videoLength: videoDuration,
        videoWatched: percentageWatched as string,
    };
};
