import { Banner, Country } from '@royalaholddelhaize/ah-web-core';

export const gtmIdMapper: Record<Banner, { [country in Country]?: string }> = {
    [Banner.AH]: {
        [Country.NLD]: 'GTM-WQK8NCZ',
        [Country.BEL]: 'GTM-WQK8NCZ',
    },
    [Banner.GALL]: {
        [Country.NLD]: 'GTM-T662X77',
        [Country.BEL]: 'GTM-T662X77',
    },
    [Banner.ETOS]: {
        [Country.NLD]: 'GTM-N358MXZ',
        [Country.BEL]: 'GTM-N358MXZ',
    },
};
