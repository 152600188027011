import type { PromoMeta, PromoMetaProps } from '../interfaces';
import { convertPositionToListIndex } from './convert-position-to-list-index';

export const normalizePromoMeta = ({
    id,
    title,
    href,
    type,
    position,
    creativeId,
    lineItemId,
    pageRowIndex,
    promoListName,
    promoListType,
    searchId: promoSearchId,
}: PromoMetaProps): PromoMeta => {
    return {
        promoId: id ?? '',
        promoName: title ?? '',
        promoListIndex: convertPositionToListIndex(position),
        ...(creativeId && { promoCreativeId: String(creativeId) }),
        ...(href && { promoCreativeUrl: href }),
        ...(pageRowIndex && { promoRowIndex: pageRowIndex }),
        ...(lineItemId && { promoLineItemId: String(lineItemId) }),
        ...(type && { promoType: type }),
        ...(promoListName && { promoListName }),
        ...(promoListType && { promoListType }),
        ...(promoSearchId && { promoSearchId }),
    };
};
