import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { FixedSlotAction } from '../enums';
import type {
    EmitFixedSlotEventParams,
    FixedSlotMeta,
    FixedSlotNavigationMethodType,
} from '../interfaces';

export const emitFixedSlotEvent = ({
    slotAction,
    slots,
    slotsTotal,
    slotsNavigationMethod,
}: EmitFixedSlotEventParams): void => {
    emitEvent({
        eventName: EventName.FIXED_SLOT_INTERACTION,
        slotAction,
        slots,
        slotsTotal,
        slotsNavigationMethod,
    });
};

export const emitViewFixedSlots = (
    fixedSlotMeta: FixedSlotMeta[],
    slotsTotal: number,
    slotsNavigationMethod: FixedSlotNavigationMethodType,
) => {
    emitFixedSlotEvent({
        slotAction: FixedSlotAction.VIEW_SLOTS,
        slots: fixedSlotMeta,
        slotsTotal,
        slotsNavigationMethod,
    });
};

export const emitSelectFixedSlot = (
    fixedSlotMeta: FixedSlotMeta[],
    slotsTotal: number,
) => {
    emitFixedSlotEvent({
        slotAction: FixedSlotAction.SELECT_SLOT,
        slots: fixedSlotMeta,
        slotsTotal,
    });
};
