export const TEST_HOOKS_AH_CHECK_IN = {
    TIMESLOT: {
        TIME_SLOT_BLOCK: 'delivery-moment-block',
        SLOT_LIST_CONTAINER: 'slot-list-container',
    },
    MERGE_ORDER: {
        NEW_ORDER_MERGE_PAGE: 'merge-order-new-order',
        ORDERS_LIST: 'slots-list',
    },
    ADDRESS_CHECK: {
        CREATE_PROFILE: 'make-profile-button',
        HOUSE_NUMBER: 'address-house-number',
        POSTAL_CODE: 'address-postal-code',
        STREET: 'address-street',
    },
};
