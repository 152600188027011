// for the time being we have both, when every functionality has been migrated to the new consent we can remove cookie-consent from the datalayer
export const getConsent = () => {
    if (typeof document === 'undefined' || !document?.cookie) {
        return '';
    }

    const cookieConsentRegex = new RegExp(
        '(^| )' + 'cookie-consent' + '=([^;]+)',
    );

    const match = document.cookie.match(cookieConsentRegex);

    return match ? match[2] : '';
};

export const getConsentBeta = () => {
    if (typeof document === 'undefined' || !document?.cookie) {
        return '';
    }

    const cookieConsentRegex = new RegExp('(^| )' + 'consentBeta' + '=([^;]+)');

    const match = document.cookie.match(cookieConsentRegex);

    if (match) {
        const consent = match[2];

        try {
            // Attempt to decode the base64 string
            return atob(consent);
        } catch (_e) {
            return match[2]; // Return the original value, in case it's not a base64 string
        }
    }

    return '';
};
