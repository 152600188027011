export enum PageType {
    ACCOUNT = 'account',
    ALLERHANDE = 'allerhande',
    BONUS = 'bonus',
    BRAND = 'brand',
    BUSINESS = 'business',
    CONTENT = 'content',
    CONVERSION_FUNNEL = 'conversion_funnel',
    COOKBOOK = 'cookbook',
    CUSTOMER_CARE = 'CUSTOMER_CARE',
    FAKE_PRODUCT = 'fakeproduct',
    GRID = 'grid',
    HOMEPAGE = 'homepage',
    MY_LIST = 'mylist',
    FAVORITES = 'favorites',
    ORDERS = 'orders',
    PREMIUM = 'premium',
    PRODUCT = 'product',
    PRODUCT_CATEGORY = 'productcategory',
    PRODUCT_CATEGORY_OVERVIEW = 'productcategoryoverview',
    PROFILE = 'profile',
    PURCHASE_HISTORY = 'purchasehistory',
    SEARCH = 'search',
    STORES = 'stores',
}
