import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { PromoAction } from '../enums';
import { normalizePromoMeta } from '../helpers/normalize-promo-meta';
import type { PromoMetaProps } from '../interfaces';

export type OfferEventDetails = {
    promos: PromoMetaProps[];
    promoAction: PromoAction;
    nonInteraction: boolean;
};

export const emitOfferInteraction = ({
    promos,
    promoAction,
    nonInteraction,
}: OfferEventDetails): void => {
    const promoMeta = promos?.map(normalizePromoMeta);

    emitEvent({
        eventName: EventName.OFFER_INTERACTION,
        promoAction,
        nonInteraction,
        promos: promoMeta,
    });
};

export const emitViewPromo = (promo: PromoMetaProps): void => {
    emitOfferInteraction({
        promoAction: PromoAction.VIEW,
        nonInteraction: true,
        promos: [promo],
    });
};

export const emitViewPromos = (promos: PromoMetaProps[]): void => {
    emitOfferInteraction({
        promoAction: PromoAction.VIEW,
        nonInteraction: true,
        promos,
    });
};

export const emitClickPromo = (promo: PromoMetaProps): void => {
    emitOfferInteraction({
        promoAction: PromoAction.CLICK,
        nonInteraction: false,
        promos: [promo],
    });
};
