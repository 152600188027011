export const PAGES = {
    PDP: 'product-detail-page',
    TAXONOMY_OVERVIEW: 'taxonomy-overview-page',
    PRODUCT_NOT_FOUND: 'product-not-found-page',
};
export const TAXONOMY = {
    CARD: 'taxonomy-card',
    CARD_LINK: 'taxonomy-card-link',
    OVERVIEW_PAGE: 'taxonomy-overview',
};
export const BRAND = {
    HUB_TITLE: 'brand-hub-title',
    ALPHABET: 'brand-alphabet',
    ALPHABET_BUTTON: 'brand-alphabet-button',
    ALPHABET_BUTTON_NON_LETTER: 'brand-alphabet-button-non-letter',
    BRAND_LIST: 'brand-list',
    BRAND_LIST_TITLE: 'brand-list-title',
    BRAND_LIST_ITEM: 'brand-list-item',
};
export const PRODUCT = {
    TITLE: 'product-title',
    EMPHASIZED_ALLERGEN: 'emphasized-allergen',
    NUTRITION_TABLE: 'nutrition-table',
    NUTRITION_TABLE_TITLE: 'nutrition-table-title',
    NUTRITION_TABLE_HEADER: 'nutrition-table-header',
    NUTRITION_TABLE_FOOTER: 'nutrition-table-footer',
};
export const NOT_FOUND = {
    PRODUCT_TITLE: 'product-not-found-title',
    PRODUCT_TEXT: 'product-not-found-text',
};
export const UI = {
    BREADCRUMB: {
        ROOT: 'breadcrumb-nav',
        BACK_BUTTON: 'breadcrumb-nav-back-button',
        BREADCRUMBS: 'breadcrumb-nav-breadcrumbs',
        BREADCRUMB_ITEM: 'breadcrumb-nav-item',
        BREADCRUMB_HOME_ITEM: 'breadcrumb-nav-home-item',
        BREADCRUMB_LINK: 'breadcrumb-nav-link',
        BREADCRUMB_SEPARATOR: 'breadcrumb-nav-separator',
        BRAND_BUTTON: 'breadcrumb-nav-brand-button',
    },
    PRODUCT: {
        PDP_CONTENT: 'pdp-content',
        PDP_FOOTER: 'pdp-footer',
        PDP_HQ_ID: 'pdp-hq-id',
        PDP_PRICE_PRODUCT_INFO: 'pdp-price-product-info',
        PDP_HERO: 'pdp-hero',
        PDP_HERO_IMAGE: 'pdp-hero-image',
        PDP_HERO_ICONS: 'pdp-hero-icons',
        PDP_HERO_PRICE: 'pdp-hero-price',
        PDP_HERO_LOGOS: 'pdp-hero-logos',
        PDP_HERO_SHIELD: 'pdp-hero-shield',
        PDP_HERO_HIGHLIGHT: 'pdp-hero-highlight',
        PDP_HERO_HIGHLIGHTS: 'pdp-hero-highlights',
        PDP_HERO_SUMMARY: 'pdp-hero-summary',
        PDP_HERO_AVAILABILITY: 'pdp-hero-availability',
        PDP_HERO_DISCOUNT_DESCRIPTION: 'pdp-hero-discount-description',
        PDP_HERO_FAVORITE_ICON: 'pdp-hero-favorite-icon',
        PDP_HERO_PRODUCT_TITLE: 'pdp-hero-product-title',
        PDP_HERO_VARIANT_SELECTOR: 'pdp-hero-variant-selector',
        PDP_HERO_PRODUCT_BEST_BEFORE: 'pdp-hero-best-before',
        PDP_HERO_BASKET_ACTIONS: 'pdp-hero-basket-actions',
        PDP_HERO_BASKET_ACTIONS_QUANTITY_STEPPER: 'pdp-hero-basket-actions-quantity-stepper',
        PDP_HERO_BASKET_ACTIONS_ADD_TO_CART_BUTTON: 'pdp-hero-basket-actions-add-to-cart-button',
        PDP_DESCRIPTION: 'pdp-description',
        PDP_DESCRIPTION_LIST: 'pdp-description-list',
        PDP_DEFINITION_LIST: 'pdp-description-list',
        PDP_ADDITIONAL_INFORMATION: 'pdp-additional-information',
        PDP_CONTENT_WEIGHT: 'pdp-content-and-weight',
        PDP_CONTENT_WEIGHT_CONTENTS: 'pdp-content-and-weight-contents',
        PDP_CONTENT_WEIGHT_EMARK: 'pdp-content-and-weight-emark',
        PDP_CONTENT_WEIGHT_STATEMENT: 'pdp-content-and-weight-statement',
        PDP_CONTENT_WEIGHT_DEFINITION_LIST: 'pdp-content-and-weight-description-list',
        PDP_CLIMATE_INFO: 'pdp-climate-info',
        PDP_LOGOS: 'pdp-logos',
        PDP_INGREDIENTS: 'pdp-ingredients',
        PDP_INGREDIENTS_LIST: 'pdp-ingredients-list',
        PDP_INGREDIENTS_ALLERGENS: 'pdp-ingredients-allergens',
        PDP_INGREDIENTS_ALLERGENS_LIST: 'pdp-ingredients-allergens-list',
        PDP_INGREDIENTS_ANIMAL_FEEDING: 'pdp-ingredients-animal-feeding',
        PDP_INGREDIENTS_ANIMAL_FEEDING_ANALYTICAL_CONSTITUENTS: 'pdp-ingredients-animal-feeding-analytical-constituents',
        PDP_INGREDIENTS_ANIMAL_FEEDING_ADDITIVES: 'pdp-ingredients-animal-feeding-additives',
        PDP_INGREDIENTS_NON_FOOD: 'pdp-ingredients-non-food',
        PDP_INGREDIENTS_NON_FOOD_LIST: 'pdp-ingredients-non-food-list',
        PDP_INGREDIENTS_NON_FOOD_TABLE: 'pdp-ingredients-non-food-table',
        PDP_NUTRICIONAL_INFO: 'pdp-nutricional-info',
        PDP_USAGE_INFO: 'pdp-usage-info',
        PDP_USAGE_INFO_LIST: 'pdp-usage-info-list',
        PDP_USAGE_INFO_STATEMENT: 'pdp-usage-info-statement',
        PDP_USAGE_BACTERIA_WARNING: 'pdp-usage-bacteria-warning',
        PDP_USAGE_SIDEEFFECTS_WARNING: 'pdp-usage-sideeffects-warnings',
        PDP_USAGE_SIDEEFFECTS_WARNING_LIST: 'pdp-usage-sideeffects-warnings-list',
        PDP_USAGE_ATTACHMENTS: 'pdp-usage-attachments',
        PDP_USAGE_HAZARD_STATEMENTS: 'pdp-usage-hazard-statements',
        PDP_USAGE_PRECAUTIONS: 'pdp-usage-precautions',
        PDP_STORAGE_INFO: 'pdp-storage-info',
        PDP_STORAGE_INFO_LIST: 'pdp-storage-info-list',
        PDP_ORIGIN_INFO: 'pdp-origin-info',
        PDP_ORIGIN_INFO_LIST: 'pdp-origin-info-list',
        PDP_CONTACT_INFO: 'pdp-contact-info',
        PDP_CONTACT_INFO_NAME: 'pdp-contact-info-name',
        PDP_CONTACT_INFO_ADDRESS: 'pdp-contact-info-address',
        PDP_CONTACT_INFO_COMMUNICATION_CHANNELS: 'pdp-contact-info-communication-channels',
        PDP_ADDITIONAL_INFO: 'pdp-additional-info',
        PDP_ADDITIONAL_INFO_LIST: 'pdp-additional-info-list',
    },
};
export const COMPONENTS = {
    OTHER_SORTS_PANEL: {
        OTHER_SORTS_PANEL: 'other-sorts-panel',
        OTHER_SORTS_PRODUCT_CARD: 'other-sorts-product-card',
    },
    CROSS_SELL_PANEL: {
        CROSS_SELL_PANEL: 'cross-sell-panel',
        CROSS_SELL_PANEL_PRODUCT_CARD: 'cross-sell-product-card',
    },
    FAVORITES_PANEL: {
        FAVORITES_PANEL: 'favorites-panel',
        HEADER: 'favorites-panel-header',
        CONTENT: 'favorites-panel-content',
        ADD_TO_LIST: 'favorites-panel-add-to-list',
        CREATE_NEW_LIST: 'favorites-panel-create-new-list',
    },
    IMAGE_CAROUSEL: {
        IMAGE_CAROUSEL: 'image-carousel',
        DISPLAY_IMAGE: 'image-carousel-display-image',
        NAV: 'image-carousel-nav',
        DOT_BUTTON: 'image-carousel-dot-button',
        THUMBNAIL_BUTTON: 'image-carousel-thumbnail-button',
        THUMBNAIL_IMAGE: 'image-carousel-thumbnail-image',
    },
    COLLAPSIBLE_INFO: {
        COLLAPSIBLE_INFO: 'collapsible-product-info',
        COLLAPSIBLE_INFO_ITEM: 'collapsible-product-info-item',
        COLLAPSIBLE_INFO_TITLE: 'collapsible-product-info-title',
        COLLAPSIBLE_INFO_CONTENT: 'collapsible-product-info-content',
    },
};
