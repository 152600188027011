export const FixedSlotAction = {
    /** The customer gets to see all the fixed slots */
    VIEW_SLOTS: 'viewSlots',

    /** The customer selects one of the fixed slots */
    SELECT_SLOT: 'selectSlot',

    /** The customer navigates through the dates */
    NAVIGATE_SLOTS: 'navigateSlots',
} as const;

export const FixedSlotNavigationMethod = {
    /** Click on a day tile to view the fixed slots for that specific day (e.g. Friday)  */
    DAY_CLICK: 'dayClick',

    /** Applicable when loading slots field, on page load. */
    ON_LOAD: 'onLoad',
} as const;

export const FixedSlotType = {
    NORMAL: 'normal',
    SUSTAINABLE: 'sustainable',
    MILKMAN_ROUTE: 'milkman_route',
} as const;
