export const componentInteractionEventName = 'componentInteraction';

export enum ComponentAction {
    VIEW = 'view',
    CLICK = 'click',
    SWIPE = 'swipe',
    SUBMIT = 'submit',
    ADD = 'add',
    REMOVE = 'remove',
    ERROR = 'error',
    VALID = 'valid',
    CANCEL = 'cancel',
    UNDO = 'undo',
    ACCEPT = 'accept',
    CLOSE = 'close',
}
