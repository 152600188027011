export { GoogleTagManager } from './components/google-tag-manager/head';
export { GoogleTagManagerFallback } from './components/google-tag-manager/body';

// datalayer
export {
    initDataLayer,
    startEventTracking,
} from './common/datalayer/helpers/init-datalayer';
export { getDataLayer } from './common/datalayer/helpers/get-datalayer';
export type {
    DataLayerProps,
    UserMeta,
    OrderMeta,
    LoginState,
} from './common/datalayer/interfaces';

// hook
export { createAnalyticsHook } from './events/hook';

//_default
export { emitEvent } from './events/_default/emitters';
export {
    EventName,
    MemberStatus,
    MemberLoginState,
    UserLoginStatus,
} from './events/_default/enums';
export type {
    EventType,
    EventRaw,
    StandardEventMetaData,
    StandardEvent,
} from './events/_default/interfaces';

// component
export {
    emitComponentEvent,
    emitClickComponent,
    emitSwipeComponent,
    emitViewComponent,
    emitSubmitComponent,
    emitAddComponent,
    emitRemoveComponent,
    emitErrorComponent,
    emitValidComponent,
    emitCancelComponent,
    emitUndoComponent,
    emitAcceptComponent,
    emitCloseComponent,
} from './events/component/emitters';
export {
    componentInteractionEventName,
    ComponentAction,
} from './events/component/enums';
export type {
    ComponentMeta,
    ComponentInteractionEvent,
} from './events/component/interfaces';

// ecommerce
export {
    emitAddProductsToCart,
    emitAddProductsToFavorites,
    emitBasketUpdate,
    emitPurchase,
    emitRemoveProductsFromFavorites,
    emitRemoveProductsFromCart,
    emitSelectProducts,
    emitViewProduct,
    emitViewProductList,
} from './events/ecommerce/emitters';
export { productBatchService } from './events/ecommerce/batch-service';
export {
    ecommerceInteractionEventName,
    ProductIdType,
    EcommerceAction,
} from './events/ecommerce/enums';
export type {
    ProductMeta,
    ProductMetaProps,
    ProductMetaPropsWithQuantity,
    EcommerceInteractionEvent,
} from './events/ecommerce/interfaces';

// page
export { emitPageView } from './events/page/emitters';
export { PageSource } from './events/page/enums';
export type { PageViewEvent } from './events/page/interfaces';
export { PageType } from './common/datalayer/enums/page-types';

// promo
export {
    emitClickPromo,
    emitViewPromo,
    emitViewPromos,
} from './events/promo/emitters';
export { PromoAction } from './events/promo/enums';
export { promoBatchService } from './events/promo/batch-service';
export type {
    PromoMeta,
    PromoMetaProps,
    OfferInteractionEvent,
} from './events/promo/interfaces';

// recipe
export {
    emitAddRecipeToFavorites,
    emitInitializeFavoriteRecipes,
    emitMoveFavoriteRecipe,
    emitRateRecipe,
    emitRemoveRecipeFromFavorites,
    emitSelectRecipe,
    emitUpdateRecipeServingSize,
    emitViewRecipe,
    emitViewRecipeList,
    emitOpenRecipeVariantList,
    emitSelectRecipeVariant,
} from './events/recipe/emitters';
export { recipeBatchService } from './events/recipe/batch-service';
export { RecipeAction } from './events/recipe/enums';
export type {
    RecipeMeta,
    RecipeMetaRaw,
    RecipeMetaRawWithRating,
    RecipeInteractionEventProps,
    RecipeInteractionEvent,
} from './events/recipe/interfaces';

// search
export {
    finalizeSearch,
    STALE_SEARCH_THRESHOLD_MS,
    emitSearchEvent,
} from './events/search/emitters';
export {
    SearchType,
    SearchOrigin,
    SearchAction,
    SESSION_STORAGE_KEY,
} from './events/search/enums';
export {
    SearchSuggestionType,
    SearchSection,
    ProductFilterBlockType,
    QueryParamKeys,
} from './events/search/interfaces/search-auxilliary-types';
export type {
    Suggestion,
    InitializedSearchProps,
    FilterBlockConfig,
    Filters,
    Sorting,
    SearchFiltersAndSorting,
} from './events/search/interfaces/search-auxilliary-types';
export type {
    SearchTaxonomy,
    SearchMeta,
    SearchInteractionEvent,
    FinalizeSearchProps,
} from './events/search/interfaces';
export { initializeSearchEvent } from './events/search/helpers/initialize-search';

//video
export type {
    VideoMeta,
    VideoMetaRaw,
    VideoProgressMetaRaw,
    VideoInteractionEventProps,
    VideoInteractionEvent,
} from './events/video/interfaces';
export { VideoAction, VideoProvider } from './events/video/enums';
export {
    emitVideoEvent,
    emitCompleteVideo,
    emitStartVideo,
    emitVideoProgress,
} from './events/video/emitters';

// variableSlot
export type {
    VariableSlotInteractionEvent,
    VariableSlotMeta,
    EmitVariableSlotEventParams,
} from './events/variableSlot/interfaces';
export {
    VariableSlotAction,
    VariableSlotNavigationMethod,
    VariableSlotOrderMethod,
    VariableSlotOrderType,
} from './events/variableSlot/enums';
export {
    emitVariableSlotEvent,
    emitSelectSlot,
    emitViewSlots,
} from './events/variableSlot/emitters';

// fixedSlot
export type {
    EmitFixedSlotEventParams,
    FixedSlotMeta,
    FixedSlotInteractionEvent,
    FixedSlotActionType,
    FixedSlotNavigationMethodType,
} from './events/fixedSlot/interfaces';
export {
    FixedSlotNavigationMethod,
    FixedSlotAction,
    FixedSlotType,
} from './events/fixedSlot/enums';
export {
    emitSelectFixedSlot,
    emitViewFixedSlots,
    emitFixedSlotEvent,
} from './events/fixedSlot/emitters';
