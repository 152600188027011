export enum EventName {
    VARIABLE_SLOT_INTERACTION = 'variableSlotInteraction',
    PAGE_VIEW = 'pageView',
    ECOMMERCE_INTERACTION = 'ecommerceInteraction',
    FIXED_SLOT_INTERACTION = 'fixedSlotInteraction',
    OFFER_INTERACTION = 'offerInteraction',
    RECIPE_INTERACTION = 'recipeInteraction',
    SEARCH_INTERACTION = 'searchInteraction',
    COMPONENT_INTERACTION = 'componentInteraction',
    VIDEO_INTERACTION = 'videoInteraction',
}

/** reflects MemberStatus from oauth
 * @royalaholddelhaize/ah-oauth/web-proxy/constants/member-status
 * */
export enum MemberStatus {
    ANONYMOUS = 'anonymous',
    PRESUMED = 'presumed',
    AUTHORIZED = 'authorized',
}

/** Status of member */
export enum MemberLoginState {
    /** Logged in customer */
    LOGGEDON = 'LOGGEDON',
    /** Returning recognised customer, not logged in */
    RETURNING = 'RETURNING',
    /** anonymous user */
    UNKNOWN = 'UNKNOWN',
}

export enum UserLoginStatus {
    LOGGEDOUT = 'logged out',
    RECOGNIZED = 'logged out - recognized',
    TEMPORARY_ID = 'logged out - temporary id',
    LOGGEDIN = 'logged in',
}
