import type { EventRaw } from '../../../events/_default/interfaces';
import { tempHistoryName } from '../constants';
import { getDataLayer } from './get-datalayer';

export const getTempHistory = () => {
    const tempHistoryString = sessionStorage.getItem(tempHistoryName);
    const datalayer = getDataLayer();

    if (!datalayer || !tempHistoryString) {
        return;
    }

    const tempHistory = JSON.parse(tempHistoryString);
    tempHistory.forEach((event: EventRaw) => {
        datalayer.emit(event);
    });

    sessionStorage.removeItem(tempHistoryName);
};
