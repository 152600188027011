import type {
    UserMeta,
    UserMetaConstructor,
} from '../../../common/datalayer/interfaces';
import { normalizeUserLoginStatus } from './normalize-user-login-status';

export const normalizeUser = (user: UserMetaConstructor): UserMeta => {
    const { userLoginStatus, userIsRegistered, ...rest } = user;

    return {
        ...rest,
        userLoginStatus: normalizeUserLoginStatus(
            userLoginStatus,
            userIsRegistered,
        ),
    };
};
