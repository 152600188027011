export interface Batch {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    queue: any[];
    timeout?: ReturnType<typeof setTimeout>;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type Emitter = (queue: any[]) => void;

export type ConstructorProps = {
    emitter: Emitter;
    maxBatchSize?: number;
    throttleDelay?: number;
};

export class BatchService {
    throttleDelay;

    maxBatchSize;

    batch: Batch = {
        queue: [],
    };

    emitter: Emitter;

    constructor({
        emitter,
        throttleDelay = 300,
        maxBatchSize = 10,
    }: ConstructorProps) {
        this.throttleDelay = throttleDelay;
        this.maxBatchSize = maxBatchSize;
        this.emitter = emitter;
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    load(meta: any): void {
        // push meta in queue
        this.batch?.queue.push(meta);

        // if there's a timeout clear it
        if (this.batch?.timeout) {
            clearTimeout(this.batch.timeout);
        }

        // if the max size has been reached dispatch
        if (this.batch?.queue.length >= this.maxBatchSize) {
            this.dispatch();
            return;
        }

        // otherWise set timeout
        this.batch.timeout = setTimeout(
            () => this.dispatch(),
            this.throttleDelay,
        );
    }

    dispatch(): void {
        // if the batch is empty return
        if (!this.batch?.queue?.length) {
            return;
        }

        // otherwise call emitter and pass queue
        this.emitter(this.batch.queue);

        // clear queue
        this.batch = { queue: [] };
    }
}
