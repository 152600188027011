import { EventName } from '../../../events/_default/enums';
import { emitClickComponent } from '../../../events/component/emitters';
import { emitSelectProducts } from '../../../events/ecommerce/emitters';
import { emitClickPromo } from '../../../events/promo/emitters';

export const delegateClicks = (): void => {
    const body = document.querySelector('body');

    if (!body) {
        return;
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    body.addEventListener('click', (event: any) => {
        if (!event?.target) {
            return;
        }

        // catch clicks on the page and emit a CID event if it contains a data-analytics attribute
        let { target } = event;

        // traverse parents to find whether clicked element is wrapped in a button or anchor
        // to avoid clicks outside of clickable areas to be wrongfully sent to CID
        // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
        let anchorOrButton;
        while (target && target.tagName !== 'BODY') {
            if (target.tagName === 'A' || target.tagName === 'BUTTON') {
                anchorOrButton = target;
                break;
            }
            target = target.parentNode;
        }

        // traverse parents to find data-analytics attribute
        while (anchorOrButton && anchorOrButton.tagName !== 'BODY') {
            const eventName = anchorOrButton?.dataset?.analyticsCid;

            if (eventName) {
                try {
                    const analyticsMetaData =
                        anchorOrButton?.dataset?.analyticsMetaCid;

                    // optional meta property as uri-encoded stringified JSON
                    const meta = analyticsMetaData
                        ? JSON.parse(decodeURIComponent(analyticsMetaData))
                        : undefined;

                    if (eventName === EventName.ECOMMERCE_INTERACTION) {
                        emitSelectProducts([meta]);
                    } else if (eventName === EventName.OFFER_INTERACTION) {
                        emitClickPromo(meta);
                    } else if (eventName === EventName.COMPONENT_INTERACTION) {
                        emitClickComponent(meta);
                    }
                } catch (err) {
                    console.error(
                        'Error emitting click event to cidDataLayer',
                        err,
                    );
                }

                break;
            }
            anchorOrButton = anchorOrButton.parentNode;
        }
    });
};
