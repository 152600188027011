import { DeviceType } from '../enums/device-types';

export const getDeviceType = (): DeviceType => {
    if (typeof window === 'undefined') {
        return DeviceType.UNKNOWN;
    }

    const userAgent = window.navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        return DeviceType.TABLET;
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)|Appie/.test(
            userAgent,
        )
    ) {
        return DeviceType.MOBILE;
    }
    return DeviceType.DESKTOP;
};
