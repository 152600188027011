export enum RecipeAction {
    /** The number of persons is changed */
    UPDATE_SERVING_SIZE = 'updateServingSize',
    /** The rate of the recipe is changed  */
    RATE_RECIPE = 'rateRecipe',
    /** The heart icon is clicked */
    INITIALIZE_FAVORITE = 'initializeFavorite',
    /** The number of checkboxes increases */
    ADD_FAVORITE = 'addFavorite',
    /** The number of checkboxes decreases */
    REMOVE_FAVORITE = 'removeFavorite',
    /** The selection of checkboxes changed and the number of checkboxes remains unchanged */
    MOVE_FAVORITE = 'moveFavorite',
    /** viewing a list with recipes */
    VIEW_RECIPE_LIST = 'viewRecipeList',
    /** clicking on a recipe in a recipe list */
    SELECT_RECIPE = 'selectRecipe',
    /** viewing the recipe detail page */
    VIEW_RECIPE = 'viewRecipe',
    /** Open recipe variants list */
    OPEN_RECIPE_VARIANT_LIST = 'openRecipeVariant',
    /** Select a variant from the opened list */
    SELECT_RECIPE_VARIANT = 'selectRecipeVariant',
}
