import {
    DEFAULT_BANNER,
    DEFAULT_COUNTRY,
} from '@royalaholddelhaize/ah-web-core';
import type { FC } from 'react';
import { gtmDataLayerName } from '../../common/datalayer/constants';
import type { GoogleTagManagerConfig } from './config';
import { gtmIdMapper } from './gtm-id-mapper';

export const GoogleTagManager: FC<GoogleTagManagerConfig> = ({
    banner = DEFAULT_BANNER,
    domain = DEFAULT_COUNTRY,
    gtmId = gtmIdMapper[banner][domain],
    dataLayerName = gtmDataLayerName,
}) => {
    if (!gtmId) {
        throw new Error(
            '[AH-Analytics] Either a correct banner + country or a custom GTM ID prop must be provided',
        );
    }

    return (
        <script
            id="google-tag-manager"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,d,s,l,i) {
                    w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','${dataLayerName}','${gtmId}')
                `,
            }}
        />
    );
};
