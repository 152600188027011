import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { VideoAction } from '../enums';
import { normalizeVideoMeta } from '../helpers/normalize-video-meta';
import type {
    VideoInteractionEventProps,
    VideoMetaRaw,
    VideoProgressMetaRaw,
} from '../interfaces';

export const emitVideoEvent = ({
    videoMeta,
    videoAction,
}: VideoInteractionEventProps): void => {
    const video = normalizeVideoMeta(videoMeta);
    emitEvent({
        eventName: EventName.VIDEO_INTERACTION,
        videoAction,
        video,
    });
};

export const emitStartVideo = (videoMeta: VideoMetaRaw): void => {
    emitVideoEvent({
        videoAction: VideoAction.START,
        videoMeta,
    });
};

export const emitVideoProgress = (videoMeta: VideoProgressMetaRaw): void => {
    emitVideoEvent({
        videoAction: VideoAction.PROGRESS,
        videoMeta,
    });
};

export const emitCompleteVideo = (videoMeta: VideoMetaRaw): void => {
    emitVideoEvent({
        videoAction: VideoAction.COMPLETE,
        videoMeta,
    });
};
