import {
    type FilterBlockConfig,
    type Filters,
    ProductFilterBlockType,
    QueryParamKeys,
    type SearchFiltersAndSorting,
    type Sorting,
} from '../interfaces/search-auxilliary-types';
import { removeEmptyProperties } from './remove-empty-properties';

/**
 * Breaks a property value into sub-key and sub-value
 * E.g. in `kenmerk: ['nutriscore:a']` the sub-key is `nutriscore` and the sub-value is `a`
 */
const SUB_KEY_VALUE_SEPARATOR = ':';

/**
 * Maps each property parameter (from the URL) to a title of the filter block the property belongs to.
 * @param urlProperties
 * @param propIdToTitleMap
 */
function parsePropertiesFromUrl(
    urlProperties: string[],
    propIdToTitleMap: Record<string, string>,
): Record<string, string[]> {
    const usedFiltersPerBlock: Record<string, string[]> = {};

    urlProperties.forEach(property => {
        //
        // An example of a compound value is `nutriscore:a`,
        // there `nutriscrore` is the sub-key and `a` is the sub-value

        const compoundValue = property.split(SUB_KEY_VALUE_SEPARATOR);
        const isCompoundValue = compoundValue.length > 1;
        const filterKey = compoundValue.shift();

        if (filterKey && propIdToTitleMap[filterKey]) {
            const filterName = propIdToTitleMap[filterKey];
            if (!usedFiltersPerBlock[filterName]) {
                usedFiltersPerBlock[filterName] = [];
            }

            isCompoundValue
                ? usedFiltersPerBlock[filterName].push(...compoundValue)
                : usedFiltersPerBlock[filterName].push(property);
        }
    });

    return usedFiltersPerBlock;
}

/**
 * Takes a single filter block and maps all of its possible values to the title of the block.
 * @param filterBlock a filter block to parse
 * @param propIdToTitleMap a map to store the parsed values in
 */
function parseProperty(filterBlock: FilterBlockConfig) {
    const propIdToTitleMap: Record<string, string> = {};

    if (filterBlock.type === ProductFilterBlockType.PROPERTIES) {
        if (Array.isArray(filterBlock.ids)) {
            filterBlock.ids?.forEach(id => {
                propIdToTitleMap[id] = filterBlock.title;
            });
        } else {
            propIdToTitleMap[filterBlock.ids as string] = filterBlock.title;
        }
    }

    return propIdToTitleMap;
}

/**
 Extracts information on filtering and sorting from the given URL parameters,
 and transforms it into format suitable for the analytics.
 * @param urlParameters -- URL parameters to parse
 * @param filterBlocks -- filter blocks config describing the filters available to the user for particular search results
 * @returns -- Analytics-ready filter and sorting information or `undefined` if it was not possible to parse the data.
 */
export function parseFilterData(
    urlParameters: Record<string, string[]>,
    filterBlocks: FilterBlockConfig[],
): SearchFiltersAndSorting | undefined {
    if (!filterBlocks || !urlParameters) {
        return undefined;
    }

    //  `filters` and `sorting` are populated  if there's any filtering or sorting applied by the user.
    const result: SearchFiltersAndSorting = {
        filters: { Prijs: {} } as Filters,
        sorting: {} as Sorting,
    };

    // A map of property IDs to the corresponding filter titles.
    let propertyToFilterBlockName: Record<string, string> = {};

    // Populate the `propertyToFilterBlockName` map.
    // The map will be used to detect blocks of filters which the user actually used.
    filterBlocks.forEach(filterBlock => {
        if (filterBlock.type === ProductFilterBlockType.PROPERTIES) {
            propertyToFilterBlockName = {
                ...propertyToFilterBlockName,
                ...parseProperty(filterBlock),
            };
        }
    });

    // A map of filter block titles to the filter/property IDs, activated by the user.
    let selectedFiltersAndBlocks: Record<string, string[]> = {};

    // Parse URL parameters
    Object.entries(urlParameters).forEach(([key, value]) => {
        switch (key) {
            case QueryParamKeys.PROPERTIES:
                selectedFiltersAndBlocks = parsePropertiesFromUrl(
                    value,
                    propertyToFilterBlockName,
                );
                result.filters = {
                    ...result.filters,
                    ...selectedFiltersAndBlocks,
                };
                break;
            case QueryParamKeys.BRAND:
                (result.filters as Filters).Merk = value;
                break;
            case QueryParamKeys.TAXONOMY:
                (result.filters as Filters).Soort = value;
                break;
            case QueryParamKeys.SORT_BY:
                result.sorting = { order: value[0] };
                break;
            case QueryParamKeys.MIN_PRICE:
                result.filters.Prijs = {
                    ...result.filters.Prijs,
                    min: value[0],
                };
                break;

            case QueryParamKeys.MAX_PRICE:
                result.filters.Prijs = {
                    ...result.filters.Prijs,
                    max: value[0],
                };
                break;
            default:
                break;
        }
    });

    result.filters = removeEmptyProperties(result.filters) as Filters;
    return result as SearchFiltersAndSorting;
}
