/** Login page */
export const LOGIN_PAGE = {
    USERNAME: 'login-form-username',
    PASSWORD: 'login-form-password',
    SUBMIT_BUTTON: 'login-form-submit',
    MFA: 'mfa-form-code',
};
/** Customer Service landingpage */
export const CUSTOMER_SERVICE_PAGE = {
    PAGE_HEADER: 'page-header',
    SEARCH_BAR: 'search-element',
    NOTIFICATION_LANE: 'notification-lane-notification',
    SELF_SERVICE_LANE: '', //TODO create data-testhook
    LINK_LANE: 'link-lane',
    CONTACT_LANE: 'contact-lane',
    EMPLOYEE_BLOCK: 'employee-load-block',
    CONTACT_BLOCK: 'contact-block',
    CONTACT_BUTTON: 'contact-block-button',
};
/** Customer Service - Self service | General form components */
export const GENERAL_COMPONENTS = {
    FORM_TITLE: 'form-title',
    FORM_INTRO: 'form-intro',
};
/** Customer Service - Self service | Product complaint form */
export const PRODUCT_COMPLAINT_FORM = {
    TITLE: GENERAL_COMPONENTS.FORM_TITLE, //TODO add data-testhook
    BUTTON_STORE: '', //TODO create data-testhook
    BUTTON_ONLINE: '', //TODO create data-testhook
    ORDER_FIELD: 'product-complaint-order-picker',
    ORDER_LIST: 'product-complaint-order-picker-options',
    ORDER_LIST_OPTION: 'product-complaint-order-picker-option',
    ORDER_ADDRESS: '', //TODO create data-testhook'',
    INFO_BLOCK_NO_ORDERS: 'no-orders-info-segment',
    INFO_BLOCK_NO_PURCHASE: 'no-purchases-info-segment',
    PRODUCT_FIELD: 'product-complaint-product-picker',
    PRODUCT_LIST: 'product-complaint-product-picker-options',
    PRODUCT_LIST_OPTION: 'product-complaint-product-picker-option',
    PRODUCT_FIELD_STORE: 'product-select-input', //Different data-testhook for store and online
    PRODUCT_LIST_STORE: 'product-select-input-dropdown', //Different data-testhook for store and online
    PRODUCT_LIST_OPTION_STORE: 'product-select-input-option', //Different data-testhook for store and online
    PRODUCT_QUANTITY_ONLINE_FIELD: 'product-complaint-online-quantity',
    PRODUCT_QUANTITY_ONLINE_LIST: 'product-complaint-online-quantity-options',
    PRODUCT_QUANTITY_LIST_ONLINE_OPTION: 'product-complaint-online-quantity-option',
    PRODUCT_QUANTITY_STORE_FIELD: 'product-complaint-store-quantity',
    PRODUCT_QUANTITY_STORE_LIST: 'product-complaint-store-quantity-options',
    PRODUCT_QUANTITY_LIST_STORE_OPTION: 'product-complaint-store-quantity-option',
    REASON_FIELD: 'product-complaint-feedback-type',
    REASON_LIST: 'product-complaint-feedback-type-options',
    REASON_LIST_OPTION: 'product-complaint-feedback-type-option',
    REASON_FIELD_STORE: 'product-complaint-store-feedback-type',
    REASON_LIST_STORE: 'product-complaint-store-feedback-type-options',
    REASON_LIST_OPTION_STORE: 'product-complaint-store-feedback-type-option',
    PRODUCT_DATE_FIELD: '', //TODO create data-testhook
    BATCH_CODE_FIELD: '', //TODO create data-testhook
    IMAGE_UPLOAD: 'file-upload-input',
    IMAGE_UPLOAD_TEXT: 'file-upload-preview-disclaimer',
    IMAGE_EXAMPLE: 'file-upload-image-preview',
    IMAGE_UPLOAD_STORE: 'file-upload-field',
    COMMENT_FIELD: 'product-complaint-comments-input',
    INFORMATION_BLOCK: 'information-segment',
    NOTIFICATION_BOX: 'explanation-label',
    ERROR_LABEL: 'error-label',
    SETTLEMENT: 'settlement-select',
    SUBMIT_BUTTON: 'product-complaint-form-submit-button',
};
/** Customer Service - Self service | Product return form */
export const RETURN_FORM = {
    TITLE: GENERAL_COMPONENTS.FORM_TITLE,
    INTRO: GENERAL_COMPONENTS.FORM_INTRO,
    ORDER_FIELD: 'deposit-complaint-order-picker', //datahook contains 'deposit' - should be generic or form specific
    ORDER_LIST: 'undefined-options', //TODO create data-testhook
    ORDER_LIST_OPTION: 'undefined-option', //TODO create data-testhook
    ORDER_ADDRESS: '', //TODO create data-testhook
    PRODUCT_FIELD: 'product-return-product-picker',
    PRODUCT_LIST: 'product-return-product-picker-options',
    PRODUCT_LIST_OPTION: 'product-return-product-picker-option',
    PRODUCT_QUANTITY_FIELD: 'product-return-quantity-picker',
    PRODUCT_QUANTITY_LIST: 'product-return-quantity-picker-options',
    PRODUCT_QUANTITY_LIST_OPTION: 'product-return-quantity-picker-option',
    COMMENT_FIELD: 'product-return-comments-input',
    SUBMIT_BUTTON: 'product-return-form-submit-button',
    NOTIFICATION_BOX: 'explanation-information-block',
    ERROR_LABEL: 'error-label',
    ERROR_MESSAGE: 'error-message',
};
/** Customer Service - Self service | Deposit form */
export const DEPOSIT_FORM = {
    TITLE: GENERAL_COMPONENTS.FORM_TITLE, //TODO add data-testhook
    INTRO: GENERAL_COMPONENTS.FORM_INTRO,
    ORDER_FIELD: 'deposit-complaint-order-picker',
    ORDER_LIST: 'undefined-options', //TODO create data-testhook
    ORDER_LIST_OPTION: 'undefined-option', //TODO create data-testhook
    ORDER_ADDRESS: '', //TODO create data-testhook
    DEPOSIT_ITEM_FIELD: 'deposit-complaint-deposit-item-picker',
    DEPOSIT_ITEM_LIST: 'undefined-dropdown', //TODO create data-testhook
    DEPOSIT_ITEM_LIST_OPTION: 'undefined-option', //TODO create data-testhook
    DEPOSIT_NOTIFICATON: 'explanation-label',
    DEPOSIT_QUANTITY_FIELD: 'deposit-complaint-deposit-item-picker',
    DEPOSIT_QUANTITY_LIST: 'undefined-options', //TODO create data-testhook
    DEPOSIT_QUANTITY_LIST_OPTION: 'undefined-option', //TODO create data-testhook
    DEPOSIT_COMMENT_FIELD: 'deposit-complaint-comments-input',
    DEPOSIT_RADIO_BUTTON_GROUP: '', //TODO create data-testhook
    DEPOSIT_SUBMIT_BUTTON: 'deposit-complaint-form-submit-button',
};
/** Customer Service - Self service | Feedback form*/
export const FEEDBACK_FORM = {
    TITLE: GENERAL_COMPONENTS.FORM_TITLE,
    FEEDBACK_STAR_RATING: 'rating-star',
    FEEDBACK_COMMENT_FIELD: 'feedback-comments-textarea',
    FEEDBACK_UPLOAD_IMAGE: 'file-upload-input',
    FEEDBACK_IMAGE_UPLOAD_TEXT: 'file-upload-preview-disclaimer',
    FEEDBACK_IMAGE_EXAMPLE: 'file-upload-image-preview',
    FEEDBACK_TYPE_FIELD: 'feedback-options-filter',
    FEEDBACK_TYPE_LIST: 'feedback-options-filter-options',
    FEEDBACK_TYPE_LIST_OPTION: 'feedback-options-filter-option',
    FEEDBACK_SUBTYPE_FIELD: 'feedback-options-child',
    FEEDBACK_SUBTYPE_LIST: 'feedback-options-child-options',
    FEEDBACK_SUBTYPE_LIST_OPTION: 'feedback-options-child-option',
    FEEDBACK_LOGIN: 'navigate-to-login-button',
    FEEDBACK_ORDER_FIELD: 'feedback-order-selector',
    FEEDBACK_ORDER_LIST: 'feedback-order-selector-dropdown',
    FEEDBACK_ORDER_LIST_OPTION: 'feedback-order-selector-option',
    FEEDBACK_STORE_FINDER: 'search-combobox-input',
    FEEDBACK_STORE_LIST_OPTION: 'search-combobox-option',
    FEEDBACK_SUBMIT_BUTTON: 'feedback-form-submit-button',
};
/** Customer Service - Self service | Succespage Feedback form */
export const SUCCESS_PAGE_FEEDBACK_FORM = {
    SUCCESS_PAGE: 'feedback-success',
    MORE_FEEDBACK_BUTTON: 'feedback-reset-button',
};
/** Customer Service - Self service | Succespage Self service form */
export const SUCCESS_PAGE_SELFSERVICE_FORM = {
    SUCCESS_PAGE: 'product-complaint-success',
    SUCCESS_HIGHLIGHT: 'success-highlight',
    SUCCESS_SUMMARY: 'product-complaint-summary',
    SUMMARY_SUBTITLE: 'subtitle',
    SUMMARY_PRODUCTTITLE: 'product-title',
    SUMMARY_AMOUNT: 'amount',
    SUCCESS_BODY: 'success-body',
    MORE_COMPLAINTS_BUTTON: 'product-complaint-button',
    MORE_RETURN_BUTTON: 'product-return-button',
    MORE_DEPOSIT_BUTTON: 'deposit-complaint-button',
};
/** Customer Service - Self service | Settlements overview */
export const SETTLEMENT_OVERVIEW = {
    PAGE_TITLE: 'settlements-page-title',
    SETTLEMENT_LIST_TITLE: 'settlements-bucket-title',
    SETTLEMENT_LIST: 'settlements-bucket',
    SETTLEMENT: 'settlement',
    SETTLEMENTS_SUMMARY: 'settlements-conclusion',
    PAGE_OUTRO: 'settlements-page-outro',
};
