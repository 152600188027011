import {
    SearchSuggestionType,
    type SearchTaxonomy,
    SectionLabel,
    type Suggestion,
    type TaxonomyId,
    type TaxonomyName,
} from '../interfaces';

/**
 * Normalize the suggestions for a given section.
 * The result is an array of suggestion labels.
 * NB: suggestions of type `MORE_LINK` are filtered out.
 * @param suggestions
 */
function normalizeSection(suggestions?: Suggestion[]): string[] | undefined {
    return suggestions
        ?.filter(
            suggestion => suggestion.type !== SearchSuggestionType.MORE_LINK,
        )
        .map(suggestion => suggestion.label);
}

/**
 * Gets an array of search suggestions from a given section.
 * @param suggestions -- the top-level suggestions object
 * @param sectionLabel -- the label of the section to extract
 */
function extractSection(
    suggestions: Suggestion[],
    sectionLabel: SectionLabel,
): Suggestion[] | undefined {
    const matchedSection = suggestions.find(
        suggestion => suggestion.label === sectionLabel,
    );
    return matchedSection?.suggestions;
}

/**
 *  Convert the suggestions from the raw form provided by `ah-ui-navigation` to format expected by the analytics.
 *  Example of the result:
 *          {
 * 		     "products": ["melk", "kokosmelk", "karnemelk", "koffiemelk", "chocolademelk", "opvolgmelk"],
 * 		     "recipes": ["Melkchocoladefondue met kokos", "kokosmelk", "melkchocolade"]
 * 	         }
 * @param suggestions
 */
export function normalizeSearchSuggestions(
    suggestions: Suggestion[],
): Record<string, string[]> {
    // Extract suggestions per section from the top-level suggestions object
    const sections = {
        products: extractSection(suggestions, SectionLabel.PRODUCTS),
        recipes: extractSection(suggestions, SectionLabel.RECIPES),
        popularSearchTerms: extractSection(
            suggestions,
            SectionLabel.POPULAR_SEARCHES,
        ),
        previouslySearched: extractSection(
            suggestions,
            SectionLabel.PREVIOUS_SEARCHES,
        ),
    };

    // Normalize the suggestions for each section
    return {
        ...(sections.products && {
            products: normalizeSection(sections.products),
        }),
        ...(sections.recipes && {
            recipes: normalizeSection(sections.recipes),
        }),
        ...(sections.popularSearchTerms && {
            popularSearchTerms: normalizeSection(sections.popularSearchTerms),
        }),
        ...(sections.previouslySearched && {
            previouslySearched: normalizeSection(sections.previouslySearched),
        }),
    };
}

/** A map: [TaxonomyId] -> taxonomyName */
type TaxonomyNodeMap = Record<TaxonomyId, TaxonomyName>;

/**
 * Convert the taxonomies from the native form provided by `ah-products` to format expected by the analytics.
 * @param taxonomies
 */
export const normalizeTaxonomies = (
    taxonomies: SearchTaxonomy[],
): TaxonomyNodeMap => {
    return (
        taxonomies?.reduce((acc: TaxonomyNodeMap, obj) => {
            const { id, name } = obj;
            acc[id] = name;
            return acc;
        }, {}) || {}
    );
};

/**
 * Returns the web path of the page where the search was initiated.
 * E.g. if the user is on the Bonus page and performs a search,
 * the search origin is `/bonus`.
 */
export function getSearchOrigin(): string {
    if (typeof window === 'undefined') {
        return '';
    }

    if (!document.referrer) {
        return '';
    }

    const urlObj = new URL(document.referrer);

    return urlObj.pathname;
}
