export const DATA_TEST_HOOK_KEY = 'data-testhook';
export const createTestHook = (testHook) => {
    if (typeof testHook !== 'string') {
        return null;
    }
    return {
        [DATA_TEST_HOOK_KEY]: testHook,
    };
};
export const selectorTestHook = (testHook) => `[${DATA_TEST_HOOK_KEY}="${testHook}"]`;
export * as AH_ALLERHANDE_TESTHOOKS from './hooks/ah-allerhande';
export * as AH_CHECKIN_TESTHOOKS from './hooks/ah-check-in';
export * as AH_CHECKOUT_TESTHOOKS from './hooks/ah-checkout';
export * as AH_PRODUCTS_TESTHOOKS from './hooks/ah-products';
export * as AH_SHOPPINGLIST_TESTHOOKS from './hooks/ah-shoppinglist';
export * as AH_STORE_TESTHOOKS from './hooks/ah-store';
export * as AH_UI_CMS_TESTHOOKS from './hooks/ah-ui-cms';
export * as AH_UI_RECIPE_CARD_TESTHOOKS from './hooks/ah-ui-recipe-card';
export * as AH_WALLET_TESTHOOKS from './hooks/ah-wallet';
export * as NEXT_POC_TESTHOOKS from './hooks/next-poc';
export * as AH_UI_NAVIGATION_TESTHOOKS from './hooks/ah-ui-navigation';
export * as AH_SELF_SERVICE_TESTHOOKS from './hooks/ah-self-service';
