import { PageType } from '../enums/page-types';

const pathToPageTypeMap: Record<string, string> = {
    '/acties': PageType.CONTENT,
    '/allerhande': PageType.ALLERHANDE,
    '/bevestigen': PageType.CONVERSION_FUNNEL,
    '/bezorgbundel': PageType.PREMIUM,
    '/bonus': PageType.BONUS,
    '/business': PageType.BUSINESS,
    '/favorieten': PageType.FAVORITES,
    '/kies-een-moment': PageType.GRID,
    '/klantenservice': PageType.CUSTOMER_CARE,
    '/kookschrift': PageType.COOKBOOK,
    '/maak-compleet': PageType.CONVERSION_FUNNEL,
    '/mijn/': PageType.ACCOUNT,
    '/mijnbestellingen': PageType.ORDERS,
    '/mijnlijst': PageType.MY_LIST,
    '/over-ah': PageType.CONTENT,
    '/premium': PageType.PREMIUM,
    // keep product paths in this order for matching purposes
    '/producten/eerder-gekocht': PageType.PURCHASE_HISTORY,
    '/producten/fakedoor': PageType.FAKE_PRODUCT,
    '/producten/merk': PageType.BRAND,
    '/producten/product': PageType.PRODUCT,
    '/producten/': PageType.PRODUCT_CATEGORY,
    '/producten': PageType.PRODUCT_CATEGORY_OVERVIEW,
    '/winkels': PageType.STORES,
    '/zoeken': PageType.SEARCH,
    // keep homepage path at the bottom for matching purposes
    '/': PageType.HOMEPAGE,
};

const paths = Object.keys(pathToPageTypeMap);

export const getPageType = (
    pagePath: string,
    defaultPageType = 'unknown',
): PageType | string => {
    const matchedPath = paths.find(path => pagePath.includes(path));
    return matchedPath ? pathToPageTypeMap[matchedPath] : defaultPageType;
};
