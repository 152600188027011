import { dataLayerName } from '../constants';
import { DataLayer } from '../datalayer';
import type { DataLayerProps } from '../interfaces';
import { delegateClicks } from './delegate-clicks';
import { getDataLayer } from './get-datalayer';
import { getTempHistory } from './get-temp-history';
import { setInitializedForGtm } from './set-intialized-for-gtm';

/**
 * Function to create cidTempHistory in session storage and
 * save events to a temp history to make sure
 * no events are lost before the data layer is fully initialized
 */
export const startEventTracking = () => {
    window.sessionStorage.setItem('cidTempHistory', '[]');
};

/** Function to fully initialize data layer with user data */
export const initDataLayer = (data: DataLayerProps): void => {
    if (!getDataLayer()) {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (window as any)[dataLayerName] = new DataLayer(data);
        delegateClicks();

        getTempHistory();

        setInitializedForGtm();
    }
};
