import type { RecipeMeta, RecipeMetaRaw } from '../interfaces';

export const normalizeRecipeMeta = (recipeRaw: RecipeMetaRaw): RecipeMeta => {
    const { id, pageRowIndex, listName, index, listType, recipeRating } =
        recipeRaw;

    return {
        recipeId: Number(id),
        recipeListName: listName,
        // increase index because should start at 1
        recipeListIndex: index ? Number(index) + 1 : 1,
        ...(listType && { recipeListType: listType }),
        ...(pageRowIndex && { pageRowIndex }),
        ...(recipeRating && { recipeRating }),
    };
};
