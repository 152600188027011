export const VariableSlotAction = {
    /** The customer gets to see all the variable slots */
    VIEW_SLOTS: 'viewSlots',

    /** The customer selects one of the variable slots */
    SELECT_SLOT: 'selectSlot',
} as const;

export const VariableSlotNavigationMethod = {
    /** Variable slots loaded for the first time */
    ON_LOAD: 'onLoad',

    /** Click on a date tile to view the variable slots for that specific date */
    DATE_CLICK: 'dateClick',

    /** Click on the right arrow to see new dates and variable slots */
    ARROW_NEXT: 'arrowNext',

    /** Click on the left arrow to see new dates and variable slots */
    ARROW_PREVIOUS: 'arrowPrevious',
} as const;

export const VariableSlotOrderType = {
    /** New order is for when a customer starts a completely new order */
    NEW_ORDER: 'newOrder',

    /** Existing order is for when a customer, for example, changes the delivery moment of an already submitted order */
    EXISTING_ORDER: 'existingOrder',
} as const;

export const VariableSlotOrderMethod = {
    /** Delivery at home */
    DELIVERY: 'delivery',

    /** Delivery at an office (for B2B customers) */
    DELIVERY_PICKUP: 'deliveryPickup',

    /** Pick up at a designated location (e.g., train station locker) */
    PICKUP: 'pickup',

    /** Pick up at an AH store */
    PICKUP_STORE: 'pickupStore',

    /** Pick up at AH or Bol headquarters */
    PICKUP_CORPORATE: 'pickupCorporate',
} as const;
