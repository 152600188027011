import { UserLoginStatus } from '../../../events/_default/enums';
import { normalizeUser } from '../../../events/_default/helpers/normalize-user';
import type { DataLayerProps, NormalizedDataLayerProps } from '../interfaces';

export const normalizeData = (
    data: DataLayerProps,
): NormalizedDataLayerProps => {
    const { user, orderId, ...rest } = data;

    if (!user) {
        return {
            ...rest,
            ...(orderId && {
                order: { orderId: Number(orderId) },
            }),
            user: {
                userLoginStatus: UserLoginStatus.LOGGEDOUT,
            },
        };
    }

    return {
        ...rest,
        ...(orderId && {
            order: { orderId: Number(orderId) },
        }),
        user: normalizeUser(user),
    };
};
