import type { LoginState } from '../../../common/datalayer/interfaces';
import { MemberLoginState, MemberStatus, UserLoginStatus } from '../enums';

export const normalizeUserLoginStatus = (
    status: MemberStatus | LoginState | MemberLoginState | undefined,
    userIsRegistered = true,
): UserLoginStatus => {
    switch (status) {
        case MemberStatus.PRESUMED:
        case MemberLoginState.RETURNING:
        case 'returning':
            return userIsRegistered
                ? UserLoginStatus.RECOGNIZED
                : UserLoginStatus.TEMPORARY_ID;
        case MemberStatus.AUTHORIZED:
        case MemberLoginState.LOGGEDON:
        case 'loggedon':
            return UserLoginStatus.LOGGEDIN;

        default:
            return UserLoginStatus.LOGGEDOUT;
    }
};
