import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { SESSION_STORAGE_KEY, type SearchAction } from '../enums';
import { getListIndex } from '../helpers/get-list-index';
import { getURLParameters } from '../helpers/get-url-parameters';
import {
    getSearchOrigin,
    normalizeSearchSuggestions,
    normalizeTaxonomies,
} from '../helpers/normalize-search-meta';
import { parseFilterData } from '../helpers/parse-filter-data';
import { removeEmptyProperties } from '../helpers/remove-empty-properties';
import { wasPageReloaded } from '../helpers/was-page-reloaded';
import type {
    FinalizeSearchProps,
    InitializedSearchProps,
    SearchMeta,
} from '../interfaces';

// If session storage key 'an-dbg' set to any truthy value, we print some debug info to the console.
const DEBUG_SES_STOR_KEY = 'an-dbg';
let DEBUG_ENABLED = false;
if (typeof window !== 'undefined') {
    DEBUG_ENABLED = !!window.sessionStorage.getItem(DEBUG_SES_STOR_KEY);
}

/**
 * How long (in milliseconds) we consider the search initialized in `ah-venture` to
 * be "fresh" and not "stale", hence being worth reported to the analytics.
 * It takes some extra time for the page(s) to (re)load while run locally, so in debug mode we set the threshold to 36 seconds.
 */
export const STALE_SEARCH_THRESHOLD_MS = DEBUG_ENABLED ? 36400 : 6400;

export const emitSearchEvent = (
    search: SearchMeta,
    searchAction: SearchAction,
): void => {
    const filteredSearch = removeEmptyProperties(
        search as unknown as Record<string, unknown>,
    ) as unknown as SearchMeta;

    if (DEBUG_ENABLED) {
        console.log(
            `emitSearchEvent(), [filtered] search event: ${JSON.stringify(
                filteredSearch,
            )}`,
        );
    }

    emitEvent({
        eventName: EventName.SEARCH_INTERACTION,
        searchAction,
        nonInteraction: true,
        search: filteredSearch,
    });
};

const voidSearch = {} as InitializedSearchProps;

/**
 * This function should be called when the search is finalized, i.e. when the user lands on the search results page.
 * This function picks up whatever preliminary search information was stored in the session storage,
 * combines it with the information passed to this function and enriches it with the information from the URL.
 * The resulting information is then reported to the analytics.
 * @param restOfInfo
 */

export const finalizeSearch = (restOfInfo: FinalizeSearchProps): void => {
    // Nothing to do if we are not in the browser.
    if (typeof window === 'undefined') {
        return;
    }

    const urlParameters = getURLParameters(window?.location?.href || '');

    if (!urlParameters?.query?.length) {
        // No search query found -- nothing to do here!
        return;
    }

    // True if the current search results page was reloaded.
    const wasReloaded = wasPageReloaded();

    // Get the search information from the session storage,
    // `ah-venture` writes it there each time the user initiates a search.
    const initializedSearchStr =
        window.sessionStorage.getItem(SESSION_STORAGE_KEY) || '{}';

    let initializedSearchJSON: InitializedSearchProps = voidSearch;

    try {
        initializedSearchJSON = JSON.parse(initializedSearchStr);
    } catch (error) {
        console.error('Error parsing initializedSearch JSON string', error);
    }

    // If the page was reloaded we should ignore the data from the session storage,
    // otherwise we would send a malformed event to the analytics.
    const initializedSearch: InitializedSearchProps = wasReloaded
        ? voidSearch
        : initializedSearchJSON;

    // Should there be no timestamp provided, we can use a very recent timestamp as a default
    const defaultTimestamp = Date.now() - 1000;
    const defaultQuery = urlParameters?.query?.[0] || '';

    // Determine the index of the selected suggestion.
    const searchListIndex = getListIndex(initializedSearch);

    // Destructure the initialized search and set sensible defaults for potentially missing properties.
    const {
        query = defaultQuery,
        selectedSuggestion = '',
        suggestions = [],
        timestamp = defaultTimestamp,
        searchAction: searchActionFromStorage,
    } = initializedSearch;

    // If the search was initialised too long ago we consider it stale.
    // We don't want to report it. Clean up the session storage and return.
    if (Date.now() - timestamp > STALE_SEARCH_THRESHOLD_MS) {
        window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
        return;
    }

    const {
        searchTaxonomies,
        searchType,
        searchAction: searchActionFromRestOfInfo,
        filterBlocks,
        searchResultAmount,
        searchId,
        searchListIndex: providedSearchListIndex,
    } = restOfInfo;

    // Search action is passed from `ah-ui-navigation` in case of `Populaire zoektermen` and `Eerder gezocht`.
    const searchAction = searchActionFromStorage || searchActionFromRestOfInfo;

    let searchTerm = query?.toLowerCase() || '';
    let searchOrigin = getSearchOrigin();

    if (wasReloaded) {
        // There can be no search term per se in case of a page reload, only the query. Hence, an empty string.
        searchTerm = '';
        searchOrigin = 'page_reload';
    }

    const filtersAndSorting = parseFilterData(urlParameters, filterBlocks);

    const search: SearchMeta = {
        searchQuery: (selectedSuggestion || query)?.toLowerCase(),
        searchTerm,
        searchOrigin,
        searchResultAmount,
        searchType,
        searchSuggestions: normalizeSearchSuggestions(suggestions),
        searchTaxonomyNodes: normalizeTaxonomies(searchTaxonomies || []) || {},
        searchId,
        searchListIndex: providedSearchListIndex || searchListIndex,
    };

    if (filtersAndSorting?.filters) {
        search.searchFilters = filtersAndSorting?.filters;
    }

    if (filtersAndSorting?.sorting) {
        search.searchSort = filtersAndSorting?.sorting.order;
    }
    window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
    emitSearchEvent(search, searchAction);
};
