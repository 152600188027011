export enum SearchType {
    PRODUCTS = 'products',
    RECIPES = 'recipes',
    STORES = 'stores',
}

export enum SearchOrigin {
    DISCOVER = 'discover',
    RECIPES = 'recipes',
    FAVORITE_RECIPES = 'favorite recipes',
    MY_LIST = 'my list',
    BONUS = 'bonus',
    MARKETING = 'marketing',
    DEEPLINK = 'deeplink',
    FALLBACK_BRANDPAGE = 'fallback brandpage',
    PRODUCTS = 'products',
    PREVIOUSLY_BOUGHT = 'previously bought',
    STORES = 'stores',
    LEFTOVERS = 'leftovers',
    PAGE_RELOAD = 'page_reload',
}

export enum SearchAction {
    SEARCH = 'search',
    TAXONOMY = 'taxonomy',
    FILTER = 'filter',
    SUGGESTION = 'suggestion',
    PREVIOUS = 'previous',
    POPULAR = 'popular',
    SORT = 'sort',
    QUICK_LINK_FILTER = 'quickLinkFilter',
}

// The key under which we store the search initialization information in the session storage.
// NB: It is very important NOT TO CHANGE this key, as it is used in other apps as well (at least in `ah-venture`, `ah-products`).
export const SESSION_STORAGE_KEY = 'ah-search_init';
