import { tempHistoryName } from '../../../common/datalayer/constants';
import type { EventType } from '../interfaces';

export const saveToTempHistory = (event: EventType) => {
    const tempHistoryString = sessionStorage.getItem(tempHistoryName) || '[]';
    const tempHistory = JSON.parse(tempHistoryString);

    tempHistory.push({
        ...event,
        timestamp: Date.now(),
    });

    sessionStorage.setItem(tempHistoryName, JSON.stringify(tempHistory));
};
