import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { EcommerceAction } from '../enums';
import { normalizeProductMeta } from '../helpers/normalize-product-meta';
import type {
    ProductMetaProps,
    ProductMetaPropsWithQuantity,
} from '../interfaces';
import type { EcommerceSearchMeta } from '../interfaces/index';

export type EcommerceEventDetails = {
    products: ProductMetaProps[];
    ecommerceAction: EcommerceAction;
    nonInteraction: boolean;
};

export const emitEcommerceEvent = ({
    products,
    ecommerceAction,
    nonInteraction,
}: EcommerceEventDetails): void => {
    const search: EcommerceSearchMeta | undefined = products?.find(
        p => p.search,
    )?.search;
    const productsMeta = products.filter(p => !!p.id).map(normalizeProductMeta);

    if (!productsMeta?.length) {
        return;
    }

    emitEvent({
        eventName: EventName.ECOMMERCE_INTERACTION,
        ecommerceAction,
        nonInteraction,
        products: productsMeta,
        ...(search && {
            search: {
                ...search,
                searchTerm: search.searchTerm?.toLowerCase(),
                searchQuery: search.searchQuery?.toLowerCase(),
            },
        }),
    });
};

export const emitViewProductList = (products: ProductMetaProps[]): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.VIEW_ITEM_LIST,
        nonInteraction: true,
        products,
    });
};

export const emitViewProduct = (product: ProductMetaProps): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.VIEW_ITEM,
        nonInteraction: true,
        products: [product],
    });
};

export const emitSelectProducts = (products: ProductMetaProps[]): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.SELECT_ITEM,
        nonInteraction: false,
        products,
    });
};

export const emitAddProductsToCart = (
    products: ProductMetaPropsWithQuantity[],
): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.ADD_TO_CART,
        nonInteraction: false,
        products,
    });
};

export const emitRemoveProductsFromCart = (
    products: ProductMetaPropsWithQuantity[],
): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.REMOVE_FROM_CART,
        nonInteraction: false,
        products,
    });
};

export const emitBasketUpdate = (
    product: ProductMetaProps,
    previousQuantity = 0,
    newQuantity = 0,
): void => {
    const isQuantityChanged = previousQuantity !== newQuantity;

    if (isQuantityChanged) {
        const isQuantityIncreased = previousQuantity < newQuantity;
        const updatedQuantity = isQuantityIncreased
            ? newQuantity - previousQuantity
            : previousQuantity - newQuantity;
        const products = [{ ...product, quantity: updatedQuantity }];

        if (isQuantityIncreased) {
            emitAddProductsToCart(products);
        } else {
            emitRemoveProductsFromCart(products);
        }
    }
};

export const emitAddProductsToFavorites = (
    products: ProductMetaPropsWithQuantity[],
): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.ADD_TO_FAVORITE,
        nonInteraction: false,
        products,
    });
};

export const emitRemoveProductsFromFavorites = (
    products: ProductMetaPropsWithQuantity[],
): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.REMOVE_FROM_FAVORITE,
        nonInteraction: false,
        products,
    });
};

export const emitPurchase = (
    products: ProductMetaPropsWithQuantity[],
): void => {
    emitEcommerceEvent({
        ecommerceAction: EcommerceAction.PURCHASE,
        nonInteraction: false,
        products,
    });
};
