import type { EventName } from '../../_default/enums';
import type { SearchAction, SearchOrigin, SearchType } from '../enums';
import type { FilterBlockConfig, Filters } from './search-auxilliary-types';

export * from '../interfaces/search-auxilliary-types';

export type TaxonomyId = number;
export type TaxonomyName = string;

export interface SearchTaxonomy {
    id: TaxonomyId;
    name: TaxonomyName;
}

export interface SearchMeta {
    // SearchTerm is what the user typed (should be null if there is no input), searchQuery is what is actually used for the search

    /** What the user actually typed into the search input field. `null` in case of no input. */
    searchTerm: string | null;

    /**
     * The actual search query sent to the backend.
     * Might be equal to `searchTerm` or to a term selected from the search suggestions.
     */
    searchQuery: string;

    /** How many items there are in the search result */
    searchResultAmount: number;

    searchTaxonomyNodes: Record<TaxonomyId, TaxonomyName>;

    searchType: SearchType;

    /**
     * Web path of the page where the search was initiated.
     * E.g. if the user is on the Bonus page and performs a search,
     *      the search origin is `/bonus`.
     */
    searchOrigin: SearchOrigin | string;

    searchFilters?: Filters;

    searchSort?: string;

    /** Suggestions shown in the dropdown to the user when they start typing in the search box */
    searchSuggestions: Record<string, string[]>;

    searchId?: string;

    /** Position of the query that is clicked in the provided list under the search bar */
    searchListIndex?: number;
}

export type SearchInteractionEvent = {
    eventName: EventName.SEARCH_INTERACTION;
    searchAction: SearchAction;
    search: SearchMeta;
    nonInteraction: boolean;
};

export type FinalizeSearchProps = {
    /** How many items were there totally in the search? */
    searchResultAmount: number;

    /** Reflects what the user does, i.e. just a search, or they apply a filter or sorting */
    searchAction: SearchAction;

    /** Each frontend app should report its type upon showing search results page */
    searchType: SearchType;

    /** Taxonomies in format native to `ah-products */
    searchTaxonomies?: SearchTaxonomy[];

    /** Search id */
    searchId?: string;

    /** Description of filter blocks displayed   */
    filterBlocks: FilterBlockConfig[];

    /** Position of the query that is clicked in the provided list under the search bar */
    searchListIndex?: number;
};
