// method to normalize position in case
// the relative position has been sent instead
// of the index of the item in the list
export const convertPositionToListIndex = (
    position: number | string,
): number => {
    if (!position) {
        return 0;
    }
    const positionLength = Math.ceil(Math.log10(Number(position) + 1));

    // if it is not a relative position (5-6 chars)
    // return as is
    if (positionLength < 5 || positionLength > 6) {
        return Number(position);
    }

    const itemIndex = position.toString().slice(positionLength - 2);

    return Number(itemIndex);
};
