function isObject(value: unknown): boolean {
    return typeof value === 'object' && value !== null;
}

function isEmptyObject(obj: Record<string, unknown>): boolean {
    return Object.keys(obj).length === 0;
}

function isEmptyArray(arr: unknown[]): boolean {
    return arr?.length === 0;
}

function isEmptyValue(value: unknown): boolean {
    return value === undefined || value === null || value === '';
}

/**
 * Removes all empty properties from the object (non-recursive).
 * Returns a new object with the non-empty properties only.
 * @param obj
 */
export function removeEmptyProperties(
    obj: Record<string, unknown>,
): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    Object.entries(obj).forEach(([key, value]) => {
        // handle primitive values
        if (!isObject(value) && !isEmptyValue(value)) {
            result[key] = value;
        }

        if (isObject(value)) {
            if (Array.isArray(value) && !isEmptyArray(value)) {
                result[key] = value;
            }
            if (!isEmptyObject(value as Record<string, unknown>)) {
                result[key] = value;
            }
        }
    });

    return result;
}
