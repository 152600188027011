export const ecommerceInteractionEventName = 'ecommerceInteraction';

export enum ProductIdType {
    PRODUCT = 'product',
    PROMOTION = 'promotion',
    FAKEDOOR = 'fakedoor',
}

export enum EcommerceAction {
    /** Viewing a list of products */
    VIEW_ITEM_LIST = 'viewItemList',
    /** Viewing the product detail page of a specific product */
    VIEW_ITEM = 'viewItem',
    /** Clicking on an item in a product list */
    SELECT_ITEM = 'selectItem',
    /** Adding a product or increasing it’s quantity to my list */
    ADD_TO_CART = 'addToCart',
    /** Removing or reducing the quantity of a product in my list */
    REMOVE_FROM_CART = 'removeFromCart',
    /** Completing an order */
    PURCHASE = 'purchase',
    /** Activating a bonus box */
    ACTIVATE = 'activate',
    /** Adding a product or increasing it’s quantity to my favorites */
    ADD_TO_FAVORITE = 'addToFavorite',
    /** Removing or reducing the quantity of a product in my favorites */
    REMOVE_FROM_FAVORITE = 'removeFromFavorite',
}
