import { tempHistoryName } from '../../../common/datalayer/constants';
import { getDataLayer } from '../../../common/datalayer/helpers/get-datalayer';
import { saveToTempHistory } from '../helpers/save-to-temp-history';
import type { EventType } from '../interfaces';

export type EmitEvent = (event: EventType) => void;

export const emitEvent: EmitEvent = event => {
    const dataLayer = getDataLayer();
    const tempHistory = sessionStorage.getItem(tempHistoryName);

    if (!dataLayer && !tempHistory) {
        console.warn(
            `Unable to emit event ${event?.eventName}. CID Datalayer not found and temp history not initialized.`,
        );
        return;
    }

    if (!dataLayer) {
        saveToTempHistory(event);
        return;
    }

    dataLayer?.emit(event);
};
