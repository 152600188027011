import type { JSXElementConstructor } from 'react';
import type { SearchAction } from '../enums';

/**
 * This file contains types collected from repo `ah-venture` / package `ah-ui-navigation`,
 * they describe the shape of search suggestions implemented in `ah-venture`.
 * The types are used to manipulate the search suggestions data recovered from the session storage.
 * The data gets written to the session storage by `ah-ui-navigation`.
 */

/**
 * Suggestion types for search.
 * This type is normally autogenerated by GQL, but we don't have GQL here.
 * The type was copied from
 *              `packages/ui/ah-ui-navigation/graphql/_generated-graphql-types.ts` in repo `ah-venture`
 *
 * NB: We should keep this type in sync with the original GQL type.
 */
export enum SearchSuggestionType {
    /** Default suggestion type. */
    DEFAULT = 'DEFAULT',
    /** Suggestion which functions as a link to view more results. */
    MORE_LINK = 'MORE_LINK',
    /** Suggestion for when there are no results found. */
    NO_RESULT = 'NO_RESULT',
    /** The section of the suggestions, e.g. products, recipes or customer care. */
    SECTION = 'SECTION',
}

export enum SearchSection {
    PRODUCTS = 'PRODUCTS',
    RECIPES = 'RECIPES',
    CUSTOMER_CARE = 'CUSTOMER_CARE',
}

export enum SectionLabel {
    PRODUCTS = 'Producten',
    RECIPES = 'Recepten',
    POPULAR_SEARCHES = 'suggestions.popular',
    PREVIOUS_SEARCHES = 'suggestions.previous',
}

export interface SvgIconProps {
    iconSize?: 'inherit' | 'small' | 'medium' | 'large' | string;
    color?: 'inherit' | string;
    className?: string;
    title?: string;
}

export interface Suggestion {
    id: string;
    label: string;
    value: string;
    href: string;
    type: SearchSuggestionType;
    icon?: JSXElementConstructor<SvgIconProps>;
    suggestions?: Suggestion[];
    section: SearchSection;
}

export type InitializedSearchProps = {
    query: string;
    suggestions: Suggestion[];
    selectedSuggestion: string | null;
    timestamp?: number;
    searchAction: SearchAction;
};

/** Copied from `ah-products` */
export enum ProductFilterBlockType {
    BRANDS = 'brands',
    TAXONOMIES = 'taxonomies',
    PROPERTIES = 'properties',
    PRICES = 'prices',
    STOCK = 'stock',
}

/** Copied from `ah-products` */
export interface FilterBlockConfig {
    type: ProductFilterBlockType;
    title: string;
    ids?: (number | string)[] | number | string;
    [key: string]: unknown; // Any additional params will be ignored
}

/** Copied from `ah-products` */
export const QueryParamKeys = {
    PROPERTIES: 'kenmerk', // +
    BRAND: 'merk', // +
    TAXONOMY: 'soort', // +
    QUERY: 'query', //  already taken into account
    PAGE: 'page', // Does not seem to be in use
    SORT_BY: 'sortBy',
    MIN_PRICE: 'minPrice', // +
    MAX_PRICE: 'maxPrice', // +
    LETTER: 'letter', // Used in converting Legacy Brand Alphabet Key, most likely can be ignored
    INSTOCK: 'inStock', // Does not seem to be in use
    AUTO_SUGGEST_ID: 'autoSuggestId', // likely to be irrelevant in the context of analytics
};

/** Description of the search filters object */
export type Filters = {
    Merk?: string[];
    Soort?: string[];
    Prijs?: { min?: string; max?: string };
} & { [key: string]: string[] };

export type Sorting = {
    order: string;
};

export type SearchFiltersAndSorting = {
    filters: Filters;
    sorting: Sorting;
};
