import { dataLayerName } from '../constants';
import type { DataLayer } from '../datalayer';

const shouldGetDataLayer = () => typeof window !== 'undefined';

export type GetDataLayer = () => DataLayer | undefined;

export const getDataLayer: GetDataLayer = () =>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    shouldGetDataLayer() ? (window as any)[dataLayerName] : undefined;
