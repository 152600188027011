import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { VariableSlotAction } from '../enums';
import type {
    EmitVariableSlotEventParams,
    VariableSlotMeta,
} from '../interfaces';

export const emitVariableSlotEvent = ({
    slotAction,
    variableSlot,
}: EmitVariableSlotEventParams): void => {
    emitEvent({
        eventName: EventName.VARIABLE_SLOT_INTERACTION,
        slotAction,
        ...variableSlot,
    });
};

export const emitViewSlots = (variableSlotMeta: VariableSlotMeta) => {
    emitVariableSlotEvent({
        slotAction: VariableSlotAction.VIEW_SLOTS,
        variableSlot: variableSlotMeta,
    });
};

export const emitSelectSlot = (variableSlotMeta: VariableSlotMeta) => {
    emitVariableSlotEvent({
        slotAction: VariableSlotAction.SELECT_SLOT,
        variableSlot: variableSlotMeta,
    });
};
