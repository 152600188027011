import { emitEvent } from '../../_default/emitters';
import { EventName } from '../../_default/enums';
import { RecipeAction } from '../enums';
import { normalizeRecipeMeta } from '../helpers/normalize-recipe-meta';
import type {
    RecipeInteractionEventProps,
    RecipeMetaRaw,
    RecipeMetaRawWithRating,
} from '../interfaces';

export const emitRecipeEvent = ({
    recipes,
    recipeAction,
    nonInteraction,
}: RecipeInteractionEventProps): void => {
    const recipeMetas = recipes.map(recipe => normalizeRecipeMeta(recipe));
    emitEvent({
        eventName: EventName.RECIPE_INTERACTION,
        recipeAction,
        nonInteraction,
        recipes: recipeMetas,
    });
};

/**
 * Event emitted when The number of persons is changed in the recipe
 * @date 03/04/2023 - 15:33:38
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitUpdateRecipeServingSize = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.UPDATE_SERVING_SIZE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted when the heart icon is clicked on recipe cards
 * @date 03/04/2023 - 15:34:17
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitInitializeFavoriteRecipes = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.INITIALIZE_FAVORITE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted when the number of checkboxes in the favorite overlay increases
 * @date 03/04/2023 - 15:35:13
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitAddRecipeToFavorites = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.ADD_FAVORITE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted when the number of checkboxes in the favorite stays the same but the content is different
 * @date 03/04/2023 - 15:35:58
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitMoveFavoriteRecipe = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.MOVE_FAVORITE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted when the number of checkboxes in the favorite overlay decreases
 * @date 03/04/2023 - 15:36:10
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitRemoveRecipeFromFavorites = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.REMOVE_FAVORITE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted when clicking on a recipe in the recipe list
 * @date 03/04/2023 - 15:36:48
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitSelectRecipe = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.SELECT_RECIPE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted when viewing a list with recipes
 * @date 03/04/2023 - 15:37:30
 *
 * @param {RecipeMetaRaw[]} recipes
 */
export const emitViewRecipeList = (recipes: RecipeMetaRaw[]): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.VIEW_RECIPE_LIST,
        nonInteraction: true,
        recipes,
    });
};

/**
 * Event emitted when viewing the recipe detail page
 * @date 03/04/2023 - 15:37:57
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitViewRecipe = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.VIEW_RECIPE,
        nonInteraction: true,
        recipes: [recipe],
    });
};

/**
 * Event emitted when rating on a recipe is changed
 * @date 03/04/2023 - 15:38:24
 *
 * @param {RecipeMetaRawWithRating} recipe
 */
export const emitRateRecipe = (recipe: RecipeMetaRawWithRating): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.RATE_RECIPE,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted open recipe variants list
 * @date 01/02/2024 - 12:18:00
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitOpenRecipeVariantList = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.OPEN_RECIPE_VARIANT_LIST,
        nonInteraction: false,
        recipes: [recipe],
    });
};

/**
 * Event emitted open recipe variants list
 * @date 01/02/2024 - 12:19:00
 *
 * @param {RecipeMetaRaw} recipe
 */
export const emitSelectRecipeVariant = (recipe: RecipeMetaRaw): void => {
    emitRecipeEvent({
        recipeAction: RecipeAction.SELECT_RECIPE_VARIANT,
        nonInteraction: false,
        recipes: [recipe],
    });
};
