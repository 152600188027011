import {
    DEFAULT_BANNER,
    DEFAULT_COUNTRY,
} from '@royalaholddelhaize/ah-web-core';
import type { FC } from 'react';
import type { GoogleTagManagerConfig } from './config';
import { gtmIdMapper } from './gtm-id-mapper';

export const GoogleTagManagerFallback: FC<GoogleTagManagerConfig> = ({
    banner = DEFAULT_BANNER,
    domain = DEFAULT_COUNTRY,
    gtmId = gtmIdMapper[banner][domain],
}) => {
    if (!gtmId) {
        throw new Error(
            '[AH-Analytics] Either a correct banner + country or a custom GTM ID prop must be provided',
        );
    }

    return (
        <noscript id="google-tag-manager-fallback">
            <iframe
                title="google-tag-manager-fallback"
                src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
            />
        </noscript>
    );
};
