import type { EventName } from './_default/enums';
import type { ComponentMeta } from './component/interfaces';

const DATA_ANALYTICS_CID = 'data-analytics-cid';
const DATA_ANALYTICS_META_CID = 'data-analytics-meta-cid';

export type AnalyticsHook = {
    [DATA_ANALYTICS_CID]?: string;
    [DATA_ANALYTICS_META_CID]?: string;
};

export const createAnalyticsHook = (
    analyticsIdCid?: EventName,
    analyticsMetaCid?: ComponentMeta,
): AnalyticsHook => ({
    [DATA_ANALYTICS_CID]: analyticsIdCid,
    [DATA_ANALYTICS_META_CID]:
        analyticsMetaCid &&
        encodeURIComponent(JSON.stringify(analyticsMetaCid)),
});
