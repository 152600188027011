export var Country;
(function (Country) {
    Country["ABW"] = "ABW";
    Country["AFG"] = "AFG";
    Country["AGO"] = "AGO";
    Country["AIA"] = "AIA";
    Country["ALA"] = "ALA";
    Country["ALB"] = "ALB";
    Country["AND"] = "AND";
    Country["ARE"] = "ARE";
    Country["ARG"] = "ARG";
    Country["ARM"] = "ARM";
    Country["ASM"] = "ASM";
    Country["ATA"] = "ATA";
    Country["ATF"] = "ATF";
    Country["ATG"] = "ATG";
    Country["AUS"] = "AUS";
    Country["AUT"] = "AUT";
    Country["AZE"] = "AZE";
    Country["BDI"] = "BDI";
    Country["BEL"] = "BEL";
    Country["BEN"] = "BEN";
    Country["BES"] = "BES";
    Country["BFA"] = "BFA";
    Country["BGD"] = "BGD";
    Country["BGR"] = "BGR";
    Country["BHR"] = "BHR";
    Country["BHS"] = "BHS";
    Country["BIH"] = "BIH";
    Country["BLM"] = "BLM";
    Country["BLR"] = "BLR";
    Country["BLZ"] = "BLZ";
    Country["BMU"] = "BMU";
    Country["BOL"] = "BOL";
    Country["BRA"] = "BRA";
    Country["BRB"] = "BRB";
    Country["BRN"] = "BRN";
    Country["BTN"] = "BTN";
    Country["BVT"] = "BVT";
    Country["BWA"] = "BWA";
    Country["CAF"] = "CAF";
    Country["CAN"] = "CAN";
    Country["CCK"] = "CCK";
    Country["CHE"] = "CHE";
    Country["CHL"] = "CHL";
    Country["CHN"] = "CHN";
    Country["CIV"] = "CIV";
    Country["CMR"] = "CMR";
    Country["COD"] = "COD";
    Country["COG"] = "COG";
    Country["COK"] = "COK";
    Country["COL"] = "COL";
    Country["COM"] = "COM";
    Country["CPV"] = "CPV";
    Country["CRI"] = "CRI";
    Country["CUB"] = "CUB";
    Country["CUW"] = "CUW";
    Country["CXR"] = "CXR";
    Country["CYM"] = "CYM";
    Country["CYP"] = "CYP";
    Country["CZE"] = "CZE";
    Country["DEU"] = "DEU";
    Country["DJI"] = "DJI";
    Country["DMA"] = "DMA";
    Country["DNK"] = "DNK";
    Country["DOM"] = "DOM";
    Country["DZA"] = "DZA";
    Country["ECU"] = "ECU";
    Country["EGY"] = "EGY";
    Country["ERI"] = "ERI";
    Country["ESH"] = "ESH";
    Country["ESP"] = "ESP";
    Country["EST"] = "EST";
    Country["ETH"] = "ETH";
    Country["FIN"] = "FIN";
    Country["FJI"] = "FJI";
    Country["FLK"] = "FLK";
    Country["FRA"] = "FRA";
    Country["FRO"] = "FRO";
    Country["FSM"] = "FSM";
    Country["GAB"] = "GAB";
    Country["GBR"] = "GBR";
    Country["GEO"] = "GEO";
    Country["GGY"] = "GGY";
    Country["GHA"] = "GHA";
    Country["GIB"] = "GIB";
    Country["GIN"] = "GIN";
    Country["GLP"] = "GLP";
    Country["GMB"] = "GMB";
    Country["GNB"] = "GNB";
    Country["GNQ"] = "GNQ";
    Country["GRC"] = "GRC";
    Country["GRD"] = "GRD";
    Country["GRL"] = "GRL";
    Country["GTM"] = "GTM";
    Country["GUF"] = "GUF";
    Country["GUM"] = "GUM";
    Country["GUY"] = "GUY";
    Country["HKG"] = "HKG";
    Country["HMD"] = "HMD";
    Country["HND"] = "HND";
    Country["HRV"] = "HRV";
    Country["HTI"] = "HTI";
    Country["HUN"] = "HUN";
    Country["IDN"] = "IDN";
    Country["IMN"] = "IMN";
    Country["IND"] = "IND";
    Country["IOT"] = "IOT";
    Country["IRL"] = "IRL";
    Country["IRN"] = "IRN";
    Country["IRQ"] = "IRQ";
    Country["ISL"] = "ISL";
    Country["ISR"] = "ISR";
    Country["ITA"] = "ITA";
    Country["JAM"] = "JAM";
    Country["JEY"] = "JEY";
    Country["JOR"] = "JOR";
    Country["JPN"] = "JPN";
    Country["KAZ"] = "KAZ";
    Country["KEN"] = "KEN";
    Country["KGZ"] = "KGZ";
    Country["KHM"] = "KHM";
    Country["KIR"] = "KIR";
    Country["KNA"] = "KNA";
    Country["KOR"] = "KOR";
    Country["KWT"] = "KWT";
    Country["LAO"] = "LAO";
    Country["LBN"] = "LBN";
    Country["LBR"] = "LBR";
    Country["LBY"] = "LBY";
    Country["LCA"] = "LCA";
    Country["LIE"] = "LIE";
    Country["LKA"] = "LKA";
    Country["LSO"] = "LSO";
    Country["LTU"] = "LTU";
    Country["LUX"] = "LUX";
    Country["LVA"] = "LVA";
    Country["MAC"] = "MAC";
    Country["MAF"] = "MAF";
    Country["MAR"] = "MAR";
    Country["MCO"] = "MCO";
    Country["MDA"] = "MDA";
    Country["MDG"] = "MDG";
    Country["MDV"] = "MDV";
    Country["MEX"] = "MEX";
    Country["MHL"] = "MHL";
    Country["MKD"] = "MKD";
    Country["MLI"] = "MLI";
    Country["MLT"] = "MLT";
    Country["MMR"] = "MMR";
    Country["MNE"] = "MNE";
    Country["MNG"] = "MNG";
    Country["MNP"] = "MNP";
    Country["MOZ"] = "MOZ";
    Country["MRT"] = "MRT";
    Country["MSR"] = "MSR";
    Country["MTQ"] = "MTQ";
    Country["MUS"] = "MUS";
    Country["MWI"] = "MWI";
    Country["MYS"] = "MYS";
    Country["MYT"] = "MYT";
    Country["NAM"] = "NAM";
    Country["NCL"] = "NCL";
    Country["NER"] = "NER";
    Country["NFK"] = "NFK";
    Country["NGA"] = "NGA";
    Country["NIC"] = "NIC";
    Country["NIU"] = "NIU";
    Country["NLD"] = "NLD";
    Country["NOR"] = "NOR";
    Country["NPL"] = "NPL";
    Country["NRU"] = "NRU";
    Country["NZL"] = "NZL";
    Country["OMN"] = "OMN";
    Country["PAK"] = "PAK";
    Country["PAN"] = "PAN";
    Country["PCN"] = "PCN";
    Country["PER"] = "PER";
    Country["PHL"] = "PHL";
    Country["PLW"] = "PLW";
    Country["PNG"] = "PNG";
    Country["POL"] = "POL";
    Country["PRI"] = "PRI";
    Country["PRK"] = "PRK";
    Country["PRT"] = "PRT";
    Country["PRY"] = "PRY";
    Country["PSE"] = "PSE";
    Country["PYF"] = "PYF";
    Country["QAT"] = "QAT";
    Country["REU"] = "REU";
    Country["ROU"] = "ROU";
    Country["RUS"] = "RUS";
    Country["RWA"] = "RWA";
    Country["SAU"] = "SAU";
    Country["SDN"] = "SDN";
    Country["SEN"] = "SEN";
    Country["SGP"] = "SGP";
    Country["SGS"] = "SGS";
    Country["SHN"] = "SHN";
    Country["SJM"] = "SJM";
    Country["SLB"] = "SLB";
    Country["SLE"] = "SLE";
    Country["SLV"] = "SLV";
    Country["SMR"] = "SMR";
    Country["SOM"] = "SOM";
    Country["SPM"] = "SPM";
    Country["SRB"] = "SRB";
    Country["SSD"] = "SSD";
    Country["STP"] = "STP";
    Country["SUR"] = "SUR";
    Country["SVK"] = "SVK";
    Country["SVN"] = "SVN";
    Country["SWE"] = "SWE";
    Country["SWZ"] = "SWZ";
    Country["SXM"] = "SXM";
    Country["SYC"] = "SYC";
    Country["SYR"] = "SYR";
    Country["TCA"] = "TCA";
    Country["TCD"] = "TCD";
    Country["TGO"] = "TGO";
    Country["THA"] = "THA";
    Country["TJK"] = "TJK";
    Country["TKL"] = "TKL";
    Country["TKM"] = "TKM";
    Country["TLS"] = "TLS";
    Country["TON"] = "TON";
    Country["TTO"] = "TTO";
    Country["TUN"] = "TUN";
    Country["TUR"] = "TUR";
    Country["TUV"] = "TUV";
    Country["TWN"] = "TWN";
    Country["TZA"] = "TZA";
    Country["UGA"] = "UGA";
    Country["UKR"] = "UKR";
    Country["UMI"] = "UMI";
    Country["URY"] = "URY";
    Country["USA"] = "USA";
    Country["UZB"] = "UZB";
    Country["VAT"] = "VAT";
    Country["VCT"] = "VCT";
    Country["VEN"] = "VEN";
    Country["VGB"] = "VGB";
    Country["VIR"] = "VIR";
    Country["VNM"] = "VNM";
    Country["VUT"] = "VUT";
    Country["WLF"] = "WLF";
    Country["WSM"] = "WSM";
    Country["YEM"] = "YEM";
    Country["ZAF"] = "ZAF";
    Country["ZMB"] = "ZMB";
    Country["ZWE"] = "ZWE";
})(Country || (Country = {}));
export const DEFAULT_COUNTRY = Country.NLD;
