export enum VideoAction {
    START = 'start',
    PROGRESS = 'progress',
    COMPLETE = 'complete',
}

export enum VideoProvider {
    YOUTUBE = 'youtube',
    BLUE_BILLYWIG = 'blue billywig',
}
