import { ProductIdType } from '../enums';
import type { ProductMeta, ProductMetaProps } from '../interfaces';

export const normalizeProductMeta = ({
    id,
    index,
    label,
    listName,
    favoriteListId,
    quantity,
    pageRowIndex,
    parentProductId,
    type = ProductIdType.PRODUCT,
    searchId: productSearchId,
    parentVagueTerm,
    auctionId,
    isSponsored,
}: ProductMetaProps): ProductMeta => {
    return {
        productId: String(id),
        productIdType: type,
        productIsSponsored: isSponsored,
        // increase index because should start at 1
        productListIndex: Number(index) + 1,
        productListName: listName,
        ...(favoriteListId && { productFavoriteListId: favoriteListId }),
        ...(quantity && { productQuantity: quantity }),
        ...(parentProductId && { productParentId: String(parentProductId) }),
        ...(pageRowIndex && { pageRowIndex }),
        ...(productSearchId && { productSearchId }),
        ...(parentVagueTerm && { productParentVagueTerm: parentVagueTerm }),
        ...(label && { productLabel: label }),
        ...(auctionId && { productAuctionId: auctionId }),
        ...(isSponsored && { productIsSponsored: isSponsored }),
    };
};
